// React, packages and api
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

// Slice and utils
import { formatCurrency, hexToRgb } from '../../../common/utils';
import { RECURRENCE_ES } from '../../../common/constants/subscriptions';

// MUI
import Grid, { GridSize } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';

// Styles and assets
import styles from './PaymentSummary.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

// Types
import { Payable } from '../../../app/type';
import { useMediaQuery } from '@mui/material';

interface CustomRecurrenceProps {
  selectedRecurrence: string | null;
  setSelectedRecurrence: (arg: string | null) => void;
  amount: number | null;
  setAmount: (arg: number | null) => void;
  errors: { [key: string]: string | null };
}

interface PaymentSummaryProps {
  payable: Payable | undefined;
  basketAmount: number;
  setBasketAmount: (arg: number) => void;
  customRecurrenceProps: CustomRecurrenceProps;
  shippingCost: number | null;
  allowCustomCheckout: boolean;
}

const PaymentSummary = (props: PaymentSummaryProps): React.ReactElement => {
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [accordionGridLength, setAccordionGridLength] = useState<number>(4);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const rgbColor = props.payable?.company.payment_design?.background_color
    ? hexToRgb(props.payable?.company.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#f5f6ff';
  const location = useLocation();
  const isPaymentPlan: boolean = location.pathname.includes('payment_plans');
  const isIndebtStatements: boolean =
    props.payable?.debt?.map((value) => (value.in_debt ? true : false)).includes(true) || false;

  useEffect(
    () =>
      setAccordionGridLength(
        isIndebtStatements ? (isPaymentPlan ? 3 : 4) : isPaymentPlan ? 4 : 4 // Rebundnacia por si en el futuro se deben hacer diferencias
      ),
    [props.payable]
  );

  const havePaymentDesign = props.payable?.company.payment_design ? true : false;

  const renderImage = () => {
    if (havePaymentDesign) {
      if (!props.payable?.company.payment_design?.show_image) {
        return null;
      }

      if (props.payable?.render_image) {
        return null;
      }

      if (props.payable?.company.payment_design?.custom_image) {
        return null;
      }

      return (
        <img
          className={styles.imageShoppingCart}
          src="https://storage.googleapis.com/onlypays-public/assets/images/shopping%20cart%20with%20boxes.svg"
          alt="Logo Producto"
        />
      );
    } else {
      return (
        <img
          className={styles.imageShoppingCart}
          src="https://storage.googleapis.com/onlypays-public/assets/images/shopping%20cart%20with%20boxes.svg"
          alt="Logo Producto"
        />
      );
    }
  };

  return (
    <>
      <div
        className={styles.paymentSummary}
        style={{
          background: cardsBackgroundColor,
          marginTop: isMobile ? '40px' : props.payable?.many_products ? '60px' : '0',
          width: isMobile && props.payable?.many_products ? '100%' : 'unset',
          minWidth: props.allowCustomCheckout ? (isMobile ? '80%' : '700px') : 'unset',
          padding:
            havePaymentDesign && !isMobile && !props.payable?.company.payment_design?.show_image
              ? '12px 30px 25px'
              : '12px 12px 25px',
        }}
      >
        <div
          className={`${styles.sellerHeaderContent} ${
            props.payable?.many_products && styles.desktopManyProducts
          }`}
        >
          {props.payable?.many_products && props.payable?.payable_products ? (
            <div className={styles.tableContainer}>
              <Grid container>
                <Grid item container className={styles.gridHeader}>
                  <Grid item xs={4}>
                    <Typography>Productos</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>Cantidad</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>Total</Typography>
                  </Grid>
                </Grid>
                {props.payable?.payable_products.map((sp, index) => (
                  <Grid item container key={index} className={styles.gridBody}>
                    <Grid item xs={4}>
                      <Typography>{sp.product.name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{sp.quantity}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{formatCurrency(sp.value, sp.product.currency)}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <Paper
              id="imageContainer"
              className={styles.paperImage}
              sx={{
                display:
                  havePaymentDesign && !props.payable?.company.payment_design?.show_image
                    ? 'none'
                    : 'unset',
                backgroundImage: props.payable?.render_image
                  ? `url(${props.payable?.render_image})`
                  : props.payable?.company.payment_design?.custom_image
                  ? `url(${props.payable?.company.payment_design?.custom_image})`
                  : 'none',
              }}
            >
              {renderImage()}
            </Paper>
          )}
          <div
            className={styles.paymentSummaryText}
            style={{ margin: props.payable?.many_products ? 'auto' : 'auto 0' }}
          >
            {props.payable?.render_amount ? (
              <div className={styles.price}>
                <Typography className={styles.amount}>
                  {props.payable.many_products && (
                    <span
                      className={styles.text}
                      style={{ color: props.payable?.company.payment_design?.background_color }}
                    >
                      Total:{' '}
                    </span>
                  )}
                  <span
                    className={styles.number}
                    style={{ color: props.payable?.company.payment_design?.background_color }}
                  >
                    {formatCurrency(
                      props.payable?.service_amount || props.payable?.render_amount,
                      props.payable?.currency || 'CLP'
                    )}
                  </span>
                  {props.payable?.recurrence && (
                    <span
                      className={styles.recurrence}
                      style={{ color: props.payable?.company.payment_design?.background_color }}
                    >
                      {' '}
                      /
                      {props.payable?.recurrence === 'custom'
                        ? `cada ${props.payable.custom_recurrence_amount} días`
                        : RECURRENCE_ES[props.payable?.recurrence]}
                    </span>
                  )}
                </Typography>
              </div>
            ) : null}
            {props.payable?.title && <Typography variant="h5">{props.payable?.title}</Typography>}
            {props.payable?.description && (
              <Typography className={styles.description}>{props.payable?.description}</Typography>
            )}
            {props.payable?.start_at && (
              <Typography sx={{ marginTop: '10px' }}>
                Comienza a cobrar el: {props.payable?.start_at}
              </Typography>
            )}
            {props.payable?.apportion_amount && (
              <div style={{ marginTop: '10px' }}>
                <Typography>Con la primera cuota prorrateada:</Typography>
                <Typography>
                  {formatCurrency(
                    props.payable?.apportion_amount,
                    props.payable?.apportion_currency || 'CLP'
                  )}
                </Typography>
              </div>
            )}
            {props.payable?.company.zp_payment_commission && (
              <Fragment>
                <div className={styles.zpCommission}>
                  <Typography>
                    Tarifa por servicio: {props.payable?.company.zp_payment_commission}%
                  </Typography>
                </div>
                <div className={styles.price}>
                  <Typography>
                    <span>
                      Total:{' '}
                      {formatCurrency(
                        Math.ceil(
                          (props.payable?.render_amount || 1) *
                            (1 + props.payable?.company.zp_payment_commission / 100)
                        ),
                        'CLP'
                      )}
                    </span>
                  </Typography>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        {props.payable?.basketable && (
          <div className={styles.priceContainer}>
            <Grid container className={styles.mainPriceGrid}>
              <Grid item xs={7}>
                <Typography variant="subtitle2">Cantidad</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="subtitle2">Total</Typography>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.ticketing}>
                  <div className={styles.lessButton}>
                    <Button
                      onClick={() => props.setBasketAmount(props.basketAmount - 1)}
                      disabled={props.basketAmount === 1}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Button>
                  </div>
                  <div className={styles.ticketAmount}>
                    <Typography variant="subtitle1">{props.basketAmount}</Typography>
                  </div>
                  <div className={styles.moreButton}>
                    <Button
                      variant="outlined"
                      onClick={() => props.setBasketAmount(props.basketAmount + 1)}
                      disabled={
                        props.payable?.available_stock
                          ? props.basketAmount >= props.payable?.available_stock
                          : false
                      }
                    >
                      <FontAwesomeIcon icon={faChevronUp} />
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={5}>
                <Typography className={styles.sellerPriceAmount}>
                  <span>
                    {props.payable?.render_amount &&
                      formatCurrency(
                        props.payable?.render_amount * props.basketAmount,
                        props.payable?.currency
                      )}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        {props.payable?.debt && props.payable.debt.length > 0 && (
          <Accordion
            expanded={showDetail}
            onChange={() => setShowDetail(!showDetail)}
            sx={{ marginBottom: '10px', marginTop: '10px', maxWidth: '100%' }}
            className={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Detalle de los cobros:</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item container className={styles.gridHeader}>
                  {isPaymentPlan && (
                    <Grid item xs={accordionGridLength as GridSize}>
                      <Typography>Nombre</Typography>
                    </Grid>
                  )}
                  <Grid item xs={accordionGridLength as GridSize}>
                    <Typography>Fecha Vencimiento</Typography>
                  </Grid>
                  <Grid item xs={accordionGridLength as GridSize}>
                    <Typography>Valor</Typography>
                  </Grid>
                  {isIndebtStatements && (
                    <Grid item xs={accordionGridLength as GridSize}>
                      <Typography>En deuda</Typography>
                    </Grid>
                  )}
                </Grid>
                {props.payable?.debt.map((value, index) => (
                  <Grid item container key={index} className={styles.gridBody}>
                    {isPaymentPlan && (
                      <Grid item xs={accordionGridLength as GridSize}>
                        {value?.name ? (
                          <Tooltip title={value.name} arrow>
                            <Typography className={styles.truncateText}>
                              {/* Es necesario el .slice, porque en caso contrario terminan rompiendo lo responsive */}
                              {value.name.slice(0, 40)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={styles.truncateText}>{'-'}</Typography>
                        )}
                      </Grid>
                    )}
                    <Grid item xs={accordionGridLength as GridSize}>
                      <Typography>{value.due_date}</Typography>
                    </Grid>
                    <Grid item xs={accordionGridLength as GridSize}>
                      <Typography>
                        {formatCurrency(
                          value.currency === 'UF' ? value.external_amount : value.amount,
                          value.currency
                        )}{' '}
                        {value.currency === 'UF' ? `(${formatCurrency(value.amount, 'CLP')})` : ''}
                      </Typography>
                    </Grid>
                    {isIndebtStatements &&
                      props.payable?.debt
                        ?.map((value) => (value.in_debt ? true : false))
                        .includes(true) && (
                        <Grid item xs={accordionGridLength as GridSize}>
                          <Typography>{value.in_debt ? 'Sí' : 'No'}</Typography>
                        </Grid>
                      )}
                  </Grid>
                ))}
                <Grid item container className={styles.gridHeader}>
                  <Grid item xs={accordionGridLength as GridSize}></Grid>
                  <Grid item xs={accordionGridLength as GridSize}>
                    <Typography style={{ whiteSpace: 'nowrap' }}>
                      <b>Total:</b> {formatCurrency(props.payable?.render_amount, 'CLP')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </>
  );
};

export default PaymentSummary;
