import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['pending', 'Pendiente'],
  ['active', 'Activa'],
  ['indebt', 'Con deuda'],
  ['canceled', 'Cancelada'],
  ['active_pending', 'En cola'],
  ['finished', 'Terminada'],
  ['no_debt', 'Al día'],
  ['paused', 'Pausada'],
  ['problem_on_charge', 'Problema en cobro'],
];

export const STATUS_COLORS = Object.fromEntries([
  ['pending', variables.pending],
  ['active', variables.success],
  ['indebt', variables.warning],
  ['canceled', variables.error],
  ['active_pending', variables.pending],
  ['finished', variables.lightygray],
  ['no_debt', variables.success],
  ['paused', variables.pending],
  ['problem_on_charge', variables.warning],
]);

export const STATUS_DETAIL = [
  ['pending', 'Inscripción que aún no tiene cuotas por pagar'],
  ['active', 'Inscripción activa'],
  ['indebt', 'Inscripción con cuotas en deuda'],
  ['canceled', 'Inscripción cancelada, no recibe pagos'],
  ['active_pending', 'Inscripción en proceso de activación'],
  ['finished', 'Inscripción terminada, no recibe pagos'],
  ['no_debt', 'Inscripción con cuotas al día'],
  ['paused', 'Inscripción pausada, no recibe pagos'],
  ['problem_on_charge', 'Inscripción con problemas en el cobro'],
];

export const STATUS_ES = Object.fromEntries(STATUS);
export const STATUS_DETAIL_ES = Object.fromEntries(STATUS_DETAIL);
