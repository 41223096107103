import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Container, Typography, Paper, List, ListItem, Divider } from '@mui/material';

import styles from './jumpseller.module.scss';
import { callbackRoute } from '../../../common/api/applications/jumpseller/app';
import ZafepayLogo from '../../../common/components/zafepayLogo';

const StoreInfo: React.FC<{ storeId: string }> = ({ storeId }) => (
  <Paper className={styles.mainPaper} elevation={3}>
    <Container className={styles.mainEditContainer}>
      <Typography variant="h5" align="center">
        Información de tu Tienda
      </Typography>
      <Typography variant="h6" align="center" className={styles.littleMarginable}>
        Código de tu tienda: {storeId}
      </Typography>
      <Typography variant="body1" align="center">
        Usa este código al contactarnos, y nosotros nos encargaremos de configurar las suscripciones
        en tu tienda de Jumpseller. Si tienes dudas, puedes escribirnos a{' '}
        <a href="mailto:hola@zafepay.com">hola@zafepay.com</a> o revisar la sección de preguntas
        frecuentes.
      </Typography>
    </Container>
  </Paper>
);

const ZafepayInfo: React.FC = () => (
  // TODO: Agregar imágenes explicativas de los pasos
  <Paper className={styles.mainPaper} elevation={3}>
    <Container className={styles.mainEditContainer}>
      <Typography variant="h5" align="center" className={styles.littleMarginable}>
        ¿Como funcionan las suscripciones con Zafepay?
      </Typography>
      <Typography variant="body1" align="center">
        Zafepay es tu aliado tecnológico en pagos y cobranzas. Gracias a nuestra alianza con
        Jumpseller, podrás aumentar la retención de clientes al ofrecerles la posibilidad de ahorrar
        tiempo evitando compras repetitivas, automatizando ese proceso. Veamos cómo:
      </Typography>

      <Divider className={styles.marginable} />

      <Typography variant="h6" align="center" fontWeight="bold">
        Primera compra de tu cliente
      </Typography>
      <Typography variant="body1" align="justify">
        <List>
          <ListItem>
            1. Elige qué productos quieres que tus clientes puedan suscribirse en tu tienda de
            Jumpseller, agregándolos a una categoría especial.
          </ListItem>
          <ListItem>
            2. Para cada producto que pertenezca a esta categoría, aparecerá un botón adicional de
            suscripción. ¡Podrás personalizar su diseño desde Zafepay!
          </ListItem>
          <ListItem>
            3. Tus clientes podrán hacer clic en el botón y serán redirigidos a un enlace de pago de
            tu tienda en Zafepay, con todo su carrito de compras.
          </ListItem>
          <ListItem>
            4. En ese enlace de pago, tus clientes podrán elegir a qué productos suscribirse, cada
            cuánto tiempo repetir el pedido y agregar su dirección de despacho. Zafepay seleccionará
            automáticamente el método de envío más económico configurado en tu tienda de Jumpseller
            y disponible para la comuna del cliente.
          </ListItem>
          <ListItem>
            5. Si hay un método de despacho disponible y suficiente stock, Zafepay permitirá a tus
            clientes proceder con el pago de su pedido. Durante el proceso, OneClick de Transbank
            almacenará la tarjeta de tu cliente de forma segura para los futuros pedidos de tu
            cliente.
          </ListItem>
          <ListItem>
            6. Una vez que el cliente complete el pago, Zafepay creará automáticamente el pedido en
            tu tienda de Jumpseller sin que tengas que hacer nada. Además, se generará una
            suscripción en tu tienda de Zafepay, desde donde podrás gestionar los próximos pedidos.
          </ListItem>
        </List>
      </Typography>

      <Divider className={styles.marginable} />

      <Typography variant="h6" align="center" fontWeight="bold">
        Compras siguientes
      </Typography>
      <Typography variant="body1">
        <List>
          <ListItem>
            1. Dos días antes de la fecha de cobro de la suscripción, Zafepay notificará a tu
            cliente por correo electrónico sobre el próximo cargo.
          </ListItem>
          <ListItem>
            2. El día del cobro, Zafepay intentará realizar el cargo a la tarjeta de tu cliente.
            Antes de hacerlo, verificará que haya suficiente stock en tu tienda de Jumpseller y
            recalculará el costo de despacho.
          </ListItem>
          <ListItem>
            3. Si todo está en orden, Zafepay procesará el pago y generará un nuevo pedido en tu
            tienda de Jumpseller. En caso de que el cobro no se pueda realizar, Zafepay notificará
            al cliente para que actualice su información de pago e intentará realizar el cobro
            nuevamente según su algoritmo de cobranza.
          </ListItem>
        </List>
      </Typography>
    </Container>
  </Paper>
);

const FrequentlyAskedQuestions: React.FC = () => (
  <Paper className={styles.mainPaper} elevation={3}>
    <Container className={styles.mainEditContainer}>
      <Typography variant="h5" align="center" style={{ margin: '10px 0px' }}>
        Preguntas Frecuentes
      </Typography>
      <Question
        question="¿Qué cosas puedo hacer desde Zafepay con las suscripciones?"
        answer="Desde tu cuenta de Zafepay puedes ver el estado de las suscripciones, cancelarlas, pausarlas, cambiar la fecha de cobro de la próxima orden, cambiar su recurrencia e incluso agregar o eliminar productos a la suscripción."
      />
      <Question
        question="¿Debo cambiar el precio de mis productos tanto en Jumpseller como en Zafepay?"
        answer="No. Zafepay está escuchando los precios de tu tienda de Jumpseller y los sincroniza automáticamente. Si cambias el precio de un producto en Jumpseller, Zafepay lo sabrá y actualizará el precio de ese producto en las suscripciones de tus clientes."
      />
      <Question
        question="¿Cómo puedo incentivar a mis clientes a suscribirse?"
        answer="Zafepay ofrece la posibilidad de dar un descuento para cada producto suscribible. También permite que crees cupones de descuentos para la primera compra de tu cliente. Estos códigos solo funcionan en los links de pago de Zafepay y no se pueden usar en tu tienda de Jumpseller, así que no debes preocuparte de que personas lo usen para otras compras que no sean suscripción."
      />
      <Question
        question="¿Qué pasa si no tengo stock de un producto que un cliente se suscribió?"
        answer="Zafepay, antes de hacer el cargo, intentará recalcular el precio de despacho. Si no existe stock suficiente entonces no se podrá hacer este cálculo y no se saldrá a cobrar ese día. En los días posteriores, Zafepay intentará cobrar de nuevo y mientras no haya stock, ni se hará el cargo ni se creará el pedido."
      />
      <Question
        question="¿Cómo funcionan los pagos con Zafepay?"
        answer='Zafepay está integrado con múltiples pasarelas de pago distintas y en tus suscripciones utilizamos el "Pago Automático de Tarjeta" (PAT) para poder guardar las tarjetas de tus clientes de forma segura. Nuestro proveedor de PAT en Jumpseller suele ser Transbank y vas a necesitar una cuenta de comercio con ellos (no te preocupes, nosotros te ayudaremos en este proceso). Cada pago que hagan tus clientes lo harán a a través de Transbank y ellos te depositará el dinero directamente en tu cuenta bancaria sin que tenga que pasar por nosotros.'
      />
      <Question
        question="¿Qué pasa si mi cliente tiene en su carrito productos que no son suscribibles?"
        answer="En el link de pago de Zafepay aparecerán todos los productos que tu cliente tiene en su carrito de compras. Si hay productos que no son suscribibles, el cliente no podrá suscribirse pero sí podrá comprarlos en la misma transacción. Esto significa que para las compras automáticas de tu cliente no aparecerán dichos items, pero para la primera compra sí."
      />
      <Question
        question="¿Cómo puede mi cliente quiere cancelar su suscripción?"
        answer="Para cancelar una suscripción, tu cliente debe contactar directamente contigo y tu desde Zafepay puedes pausar o cancelar la suscripción."
      />
    </Container>
  </Paper>
);

const Question: React.FC<{ question: string; answer: string }> = ({ question, answer }) => (
  <List>
    <Divider />
    <ListItem>
      <Typography variant="h6">{question}</Typography>
    </ListItem>
    <ListItem>
      <Typography variant="body1">{answer}</Typography>
    </ListItem>
  </List>
);

const Callback: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const code = queryParams.get('code');
  const storeId = queryParams.get('store_id');

  const query: string = 'code=' + code + '&store_id=' + storeId;

  useEffect(() => {
    if (!storeId) {
      enqueueSnackbar('Host no reconocido', {
        variant: 'error',
      });
      window.location.href = 'https://zafepay.com/';
    }

    callbackRoute(query)
      .then()
      .catch((error) => {
        console.error({ showError: error });
        console.log(error.response);
        if (error.response.status === 403) {
          enqueueSnackbar('Error: No tiene permisos para acceder a esta aplicación', {
            variant: 'error',
          });
          window.location.href = 'https://zafepay.com/';
        }
      });
  }, []);

  return (
    <Paper className={styles.mainPaper}>
      <ZafepayLogo color="color" width="350px" redirectTo="https://zafepay.com/" />
      <Container className={styles.mainEditContainer}>
        {storeId && <StoreInfo storeId={storeId} />}
        <ZafepayInfo />
        <FrequentlyAskedQuestions />
      </Container>
      <ZafepayLogo color="grey" width="150px" redirectTo="https://zafepay.com/" />
    </Paper>
  );
};

export default Callback;
