import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Root from './root';
import Callback from './callback';

const Jumpseller: React.FC = () => {
  return (
    <Switch>
      <Route path="/jumpseller/root">
        <Root />
      </Route>
      <Route path="/jumpseller/callback">
        <Callback />
      </Route>
      <Route path="/jumpseller/*">
        {() => {
          window.location.href = 'https://zafepay.com/';
          return null;
        }}
      </Route>
    </Switch>
  );
};

export default Jumpseller;
