// React, packages and api
import React, { useRef, useEffect, useState, Fragment } from 'react';
import { sellerApi } from '../../../common/api';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Slice and utils
import { useSelector, useDispatch } from '../../../app/hooks';
import { SellerState, setSubscription } from '../sellerSlice';
import { formatCurrency, setDocumentTitle } from '../../../common/utils';
import { RECURRENCE_ES } from '../../../common/constants/subscriptions';

// MUI
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Styles and assets
import {
  faEye,
  // faArrowAltCircleDown,
  faBellSlash,
  faBell,
  faTrashCan,
  faPaperPlane,
  faUpload,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import styles from './Index.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { Briefcase as BriefcaseIcon, Users as UsersIcon, Copy as CopyIcon } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Componentes
import InfoBox from '../../../common/components/InfoBox';
import Stepper from '../../../common/components/Stepper';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';
import { allowedRole, sellerActions } from '../../../common/utils/sellerAccess';

// Types
import { RecurrentDashboardData, Subscription } from '../../../app/type';

const SubscriptionsIndex = (): React.ReactElement => {
  setDocumentTitle('Suscripciones');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [sellerData, setSellerdata] = useState<RecurrentDashboardData>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const reloadRef = useRef<{ reloadResource: () => void }>();
  const [activateDialogOpen, setActivateDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [subscriptionRes, setSubscriptionRes] = useState<Subscription | null>(null);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [reportsDialogOpen, setReportsDialogOpen] = useState<boolean>(false);
  const [nextSalesPopupOpen, setNextSalesPopupOpen] = useState<boolean>(false);
  const [subscriptionBuyerReportPopup, setSubscriptionBuyerReportPopup] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [importOpen, openImport] = useState<boolean>(false);
  const [massiveSubscriptionsPopup, setMassiveSubscriptionsPopup] = useState<boolean>(false);
  const [massiveSubscriptionBuyersPopup, setMassiveSubscriptionBuyersPopup] =
    useState<boolean>(false);
  const access: string = company?.seller?.access || '';
  const resourceRoute = 'subscription';

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else {
      sellerApi.companies
        .recurrentDashboard(company.id)
        .then((data) => {
          setSellerdata(data.data);
        })
        .catch(console.error);
    }
  }, [company]);
  useEffect(() => {
    if (importOpen === false) {
      setFile(undefined);
    }
  }, [importOpen]);

  const softDelete = (res: Subscription | null) => {
    if (res) {
      sellerApi.subscriptions
        .destroy(res?.id)
        .then(() => {
          enqueueSnackbar('Suscripción eliminada', { variant: 'success' });
          reloadRef?.current?.reloadResource?.();
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('La suscripción no puede ser eliminado por tener pagos completados', {
              variant: 'error',
            });
          }
        });
    }
  };

  const changeStatus = (res: Subscription | null, resStatus: string | null) => {
    if (res && resStatus) {
      sellerApi.subscriptions
        .change_status(res?.id, resStatus)
        .then(() => {
          enqueueSnackbar('Suscripción actualizada', { variant: 'success' });
          reloadRef?.current?.reloadResource?.();
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error al actualizar la suscripción', { variant: 'error' });
        });
    }
  };

  const openActivateDialog = (res: Subscription) => {
    setActivateDialogOpen(true);
    setSubscriptionRes(res);
  };

  const closeActivateDialog = () => {
    changeStatus(subscriptionRes, subscriptionRes?.status == 'active' ? 'inactive' : 'active');
    setActivateDialogOpen(false);
  };

  const openDeleteDialog = (res: Subscription) => {
    setDeleteDialogOpen(true);
    setSubscriptionRes(res);
  };

  const closeDeleteDialog = () => {
    softDelete(subscriptionRes);
    setDeleteDialogOpen(false);
  };

  const createLink = () => {
    history.push('subscriptions/new');
  };

  function openReportsPopup() {
    setReportsDialogOpen(true);
  }

  function openNextSalesPopup() {
    setNextSalesPopupOpen(true);
  }

  function openSubscriptionBuyerReportPopup() {
    setSubscriptionBuyerReportPopup(true);
  }
  function openMassiveSubscriptionsPopup() {
    setMassiveSubscriptionsPopup(true);
  }
  function openMassiveSubscriptionBuyersPopup() {
    setMassiveSubscriptionBuyersPopup(true);
  }

  function closeNextSalesPopup() {
    setNextSalesPopupOpen(false);
  }

  function closeSubscriptionBuyerReportPopup() {
    setSubscriptionBuyerReportPopup(false);
  }

  function downloadNextSalesReport() {
    sellerApi.subscriptions
      .nextSalesReport()
      .then(() => enqueueSnackbar('¡Te enviaremos un excel a tu correo!', { variant: 'success' }))
      .catch(() =>
        enqueueSnackbar('Ha ocurrido un error, inténtalo de nuevo', { variant: 'error' })
      )
      .finally(() => {
        setReportsDialogOpen(false);
        closeNextSalesPopup();
      });
  }

  function downloadSubscriptionBuyersReport() {
    sellerApi.subscriptions
      .subscriptionBuyersReport()
      .then(() => enqueueSnackbar('¡Te enviaremos un excel a tu correo!', { variant: 'success' }))
      .catch(() =>
        enqueueSnackbar('Ha ocurrido un error, inténtalo de nuevo', { variant: 'error' })
      )
      .finally(() => {
        setReportsDialogOpen(false);
        closeSubscriptionBuyerReportPopup();
      });
  }

  function downloadSubscriptionTemplate() {
    sellerApi.subscriptions
      .subscriptionUpdateTemplate()
      .then(() =>
        enqueueSnackbar(
          'Te enviaremos un correo con la plantilla para actualizar precios de forma masiva',
          { variant: 'success' }
        )
      )
      .catch(() =>
        enqueueSnackbar('Ha ocurrido un error, inténtalo de nuevo', { variant: 'error' })
      )
      .finally(() => {
        setReportsDialogOpen(false);
      });
  }
  const handleFileChange = (event: any) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const renderImportContent = (
    <Fragment>
      <Typography variant="h6" align="center">
        Actualización masivas de precios
      </Typography>
      <Typography mt={1} mb={1} paddingX={isMobile ? 0 : 3}>
        Para actualizar los precios de forma masiva debes generar la plantilla que contiene las
        suscripciones existentes. En esta plantilla debes agregar el nuevo valor a las suscripcines
        que quieres modificar en la columna &quot;Nuevo valor numérico&quot; y escoger la moneda
        correspondiente. Finalmente debes cargar el archivo.
      </Typography>
      <div className={styles.excelImportContainer}>
        <Button
          variant="outlined"
          className={styles.excelImportButton}
          onClick={downloadSubscriptionTemplate}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faPaperPlane} /> Generar plantilla
          </Typography>
        </Button>
        <Button variant="outlined" component="label" className={styles.excelImportButton}>
          <Typography variant={isMobile ? 'h6' : 'h5'} className={styles.loadButton}>
            <FontAwesomeIcon icon={faUpload} /> Cargar archivo
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
        <Typography variant="h6" sx={{ marginRight: '20px' }} align="center">
          {file?.name}
        </Typography>
      </div>
    </Fragment>
  );

  const uploadFile = () => {
    if (file) {
      sellerApi.subscriptions
        .importSubscriptionUpdate(file)
        .then(() => {
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
        })
        .finally(() => {
          openImport(false);
          setReportsDialogOpen(false);
        });
    }
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="SUSCRIPCIONES ACTIVAS"
      data={sellerData?.total_subscriptions || 0}
      kind="info1"
      style={styles.firstInfoContainer}
      icon={<BriefcaseIcon />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="CLIENTES ACTIVOS"
      data={sellerData?.total_subscriptions_clients || 0}
      kind="info1"
      style={styles.secondInfoContainer}
      icon={<UsersIcon />}
      width={width}
    />,
    <InfoBox
      key={3}
      title="Crear Suscripción"
      function={createLink}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/young%20man%20in%20headset%20using%20computer.svg"
      style={styles.firstLinkContainer}
      width={width}
      access={access}
      route={resourceRoute}
      action={sellerActions.create}
    />,
    <InfoBox
      key={4}
      title="Acciones Masivas"
      function={openReportsPopup}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.secondLinkContainer}
      width={width}
    />,
  ];
  const DownloadGCPSubscriptionTemplate = () => {
    window.open(
      'https://storage.googleapis.com/onlypays-public/assets/files/PlantillaSuscripciones.xlsx',
      '_blank',
      'noreferrer'
    );
  };
  const renderImportService = (
    <Fragment>
      <Typography mt={1} mb={1} paddingX={isMobile ? 0 : 3}>
        Para crear suscripciones de forma masiva debes descargar el template con el formato y
        ejemplos que debes seguir para crearlos correctamente. En el encabezado de cada columna se
        agrega una descripción para aclarar qué debes ingresar en cada celda del excel. Una vez
        completado el archivo, debes cargarlo en el sistema y cuando se termine de procesar te
        llegará un mail con el resultado.
      </Typography>
      <div className={styles.excelImportContainer}>
        <Button
          variant="outlined"
          className={styles.excelImportButton}
          onClick={DownloadGCPSubscriptionTemplate}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faDownload} /> Descargar template
          </Typography>
        </Button>
        <Button variant="outlined" component="label" className={styles.excelImportButton}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faUpload} /> Cargar archivo
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
        <Typography variant="h6" sx={{ marginRight: '20px' }}>
          {file?.name}
        </Typography>
      </div>
    </Fragment>
  );

  const sendImportBuyerTemplate = () => {
    sellerApi.subscriptions
      .generateImportSubscriptionBuyersExcel(company?.id || '')
      .then(() => {
        enqueueSnackbar(
          'Te enviaremos un correo cuando hayamos terminado de generar la plantilla',
          { variant: 'info' }
        );
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
      })
      .finally(() => {
        setMassiveSubscriptionBuyersPopup(false);
      });
  };

  const renderImportBuyer = (
    <Fragment>
      <Typography mt={1} mb={1} paddingX={isMobile ? 0 : 3}>
        Para importar a tus clientes de forma masiva necesitarás primero descargar la plantilla, la
        cual en la primera hoja tendrá todos tus servicios junto con sus identificadores (primera
        columna) y en la segunda estará la plantilla a rellenar. En la plantilla deberás rellenar
        los datos de tus clientes y el servicio que desean adquirir copiando en la primera columna
        el identificador del servicio al que deseas añadirlo. Una vez completado el archivo, debes
        cargarlo en el sistema y cuando se termine de procesar te llegará un mail con el resultado.
      </Typography>
      <div className={styles.excelImportContainer}>
        <Button
          variant="outlined"
          className={styles.excelImportButton}
          onClick={sendImportBuyerTemplate}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faDownload} /> Descargar plantilla
          </Typography>
        </Button>
        <Button variant="outlined" component="label" className={styles.excelImportButton}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faUpload} /> Cargar archivo
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
        <Typography variant="h6" sx={{ marginRight: '20px' }}>
          {file?.name}
        </Typography>
      </div>
    </Fragment>
  );

  const importSubscriptionsExcel = () => {
    if (file) {
      sellerApi.subscriptions
        .importSubscriptionsExcel(file, company?.id || '')
        .then(() => {
          setFile(undefined);
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
        })
        .finally(() => {
          setMassiveSubscriptionsPopup(false);
        });
    }
  };
  const importMassiveSubscriptionBuyersExcel = () => {
    if (file) {
      sellerApi.subscriptions
        .importMassiveSubscriptionBuyersExcel(file, company?.id || '')
        .then(() => {
          setFile(undefined);
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
        })
        .finally(() => {
          setMassiveSubscriptionsPopup(false);
        });
    }
  };

  return (
    <>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <CopyIcon />
          <span className={styles.startTitle}>Suscripciones</span>
        </Typography>
        <Typography variant="body1">
          Las <b>suscripciones</b> se cobran periódicamente.
        </Typography>
      </div>

      {isMobile ? (
        <Button
          className={styles.toButton}
          onClick={() => history.push('subscriptions/new')}
          variant="contained"
        >
          Crear Suscripción
        </Button>
      ) : null}

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      <ResourceList
        title="Suscripciones"
        queryFields="Nombre"
        setResource={setSubscription}
        getResourceList={sellerApi.subscriptions.list}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.subscriptions.generateExcel('company', parentId, query, filterParams)
        }
        resourceParent={company}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'periodicity', label: 'Periodicidad' },
          { key: 'value', label: 'Valor' },
          { key: 'status', label: 'Estado' },
          { key: 'active_clients', label: 'Clientes Activos' },
          { key: 'inactive_clients', label: 'Clientes inactivos' },
        ]}
        listMobileHeaders={[
          { key: 'name' },
          { key: 'periodicity' },
          { key: 'status' },
          { key: 'value' },
        ]}
        listColumns={{
          name: (res: Subscription) => res.name,
          periodicity: (res: Subscription) => RECURRENCE_ES[res.recurrence],
          value: (res: Subscription) => formatCurrency(res.render_amount, res.currency),
          status: (res: Subscription) => <StatusLabel status={res.status} type="subscription" />,
          active_clients: (res: Subscription) => res.active_clients,
          inactive_clients: (res: Subscription) => res.inactive_clients,
        }}
        listActionsHeaders={(res?: Subscription) => {
          if (res)
            return [
              { key: 'show', label: 'Ver Suscripción', icon: faEye },
              allowedRole(access, resourceRoute, sellerActions.update)
                ? res.status == 'active'
                  ? { key: 'change_status', label: 'Inactivar', icon: faBellSlash }
                  : { key: 'change_status', label: 'Activar', icon: faBell }
                : null,
              allowedRole(access, resourceRoute, sellerActions.destroy)
                ? { key: 'delete', label: 'Eliminar', icon: faTrashCan, color: 'error' }
                : null,
            ];
          return [null];
        }}
        listActions={{
          show: (res: Subscription) => {
            dispatch(setSubscription(res));
            history.push(`/seller/subscriptions/${res.id}`);
          },
          delete: (res: Subscription) => openDeleteDialog(res),
          change_status: (res: Subscription) =>
            res.status == 'active' ? openActivateDialog(res) : changeStatus(res, 'active'),
        }}
        innerRef={reloadRef}
        filtersKey="subscriptions_index"
        persistFilters
      />
      <PopUp
        state={{ open: deleteDialogOpen, setOpen: setDeleteDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará la suscripción y se cancelarán todas las inscripciones a la suscripción,
            eliminando todas las cuotas que no se han pagado. Esta acción es irreversible. ¿Quieres
            continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeDeleteDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: activateDialogOpen, setOpen: setActivateDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se cancelarán todas las inscripciones a la suscripción y se elimiarán todas las cuotas
            que no se han pagado. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeActivateDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setActivateDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: nextSalesPopupOpen, setOpen: setNextSalesPopupOpen }}
        title={
          <Typography variant="h6" align="center">
            Confirmación
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Genera un Excel con la información de las próximas cuotas de tus suscripciones. Ten en
            cuenta que, debido a la forma en que opera Zafepay, solo se mostrarán las cuotas ya
            creadas, no las proyecciones futuras.
          </Typography>
        }
        extraActions={[
          <Button onClick={downloadNextSalesReport} color="info" variant="contained" key={1}>
            Generar
          </Button>,
          <Button onClick={closeNextSalesPopup} variant="outlined" key={2}>
            Cancelar
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: subscriptionBuyerReportPopup, setOpen: setSubscriptionBuyerReportPopup }}
        title={
          <Typography variant="h6" align="center">
            Confirmación
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Genera un Excel con todas las inscripciones del listado de subscripciones que ofreces.
          </Typography>
        }
        extraActions={[
          <Button
            onClick={downloadSubscriptionBuyersReport}
            color="info"
            variant="contained"
            key={1}
          >
            Generar
          </Button>,
          <Button onClick={closeSubscriptionBuyerReportPopup} variant="outlined" key={2}>
            Cancelar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: importOpen, setOpen: openImport }}
        content={renderImportContent}
        extraActions={[
          <Button key={1} onClick={uploadFile} variant="contained">
            Subir
          </Button>,
          <Button key={2} onClick={() => openImport(false)} variant="outlined">
            Cancelar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: reportsDialogOpen, setOpen: setReportsDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Acciones Masivas
          </Typography>
        }
        content={
          <div>
            <div className={styles.reportRow} onClick={openNextSalesPopup}>
              <Typography variant="body1">Generar reporte de próximas ventas</Typography>
            </div>
            <div className={styles.reportRow} onClick={openSubscriptionBuyerReportPopup}>
              <Typography variant="body1">Generar reporte de todas las suscripciones</Typography>
            </div>
            <div className={styles.reportRow} onClick={openMassiveSubscriptionsPopup}>
              <Typography variant="body1">Creación masiva de suscripciones</Typography>
            </div>
            <div className={styles.reportRow} onClick={openMassiveSubscriptionBuyersPopup}>
              <Typography variant="body1">Creación masiva de inscripciones</Typography>
            </div>
            {allowedRole(access, resourceRoute, sellerActions.update) && (
              <div className={styles.reportRow} onClick={() => openImport(true)}>
                <Typography variant="body1">Actualizar precios</Typography>
              </div>
            )}
          </div>
        }
      />
      <PopUp
        state={{ open: massiveSubscriptionsPopup, setOpen: setMassiveSubscriptionsPopup }}
        title={
          <Typography variant="h6" align="center">
            Creación masiva de Suscripciones
          </Typography>
        }
        content={renderImportService}
        extraActions={[
          <Button key={1} onClick={importSubscriptionsExcel} variant="contained">
            Enviar
          </Button>,
          <Button key={2} onClick={() => setMassiveSubscriptionsPopup(false)} variant="contained">
            Cancelar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: massiveSubscriptionBuyersPopup, setOpen: setMassiveSubscriptionBuyersPopup }}
        title={
          <Typography variant="h6" align="center">
            Creación masiva de inscripciones
          </Typography>
        }
        content={renderImportBuyer}
        extraActions={[
          <Button key={1} onClick={importMassiveSubscriptionBuyersExcel} variant="contained">
            Enviar
          </Button>,
          <Button
            key={2}
            onClick={() => {
              setMassiveSubscriptionBuyersPopup(false);
              setFile(undefined);
            }}
            variant="contained"
          >
            Cancelar
          </Button>,
        ]}
      />
    </>
  );
};

export default SubscriptionsIndex;
