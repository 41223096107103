import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../app/hooks';
import copyToClipboard from 'copy-text-to-clipboard';
import { useSnackbar } from 'notistack';

// API
import { sellerApi } from '../../../common/api';

// Types
import { Buyer } from '../../../app/type';

// Features
import { SellerState, setBuyer } from '../sellerSlice';

// Components
import { Button, Typography, Grid, useMediaQuery, Chip } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import styles from './Index.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { capitalizeString, setDocumentTitle } from '../../../common/utils/index';
import { FRONT_URL } from '../../../common/api/client';
import { allowedRole, sellerActions } from '../../../common/utils/sellerAccess';

import ResourceList from '../../../common/components/ResourceList';
import InfoBox from '../../../common/components/InfoBox';
import Stepper from '../../../common/components/Stepper';
import Avatar from '../../../common/components/Avatar';

const BuyersPage = (): React.ReactElement => {
  setDocumentTitle('Clientes');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [totalBuyers, setTotalBuyers] = useState<number>();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { enqueueSnackbar } = useSnackbar();
  const portalLink = `${
    company?.subdomain ? FRONT_URL.replace('app', company?.subdomain) : FRONT_URL
  }/cp/${company?.id}`;
  const access: string = company?.seller?.access || '';
  const resourceRoute = 'buyer';

  useEffect(() => {
    if (!company) history.replace('/seller/home');
    sellerApi.buyers.totalBuyers(company?.id).then((data) => setTotalBuyers(data.total));
  }, [company]);

  const onItem = (buyer: Buyer) => {
    dispatch(setBuyer(buyer));
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="TOTAL DE CLIENTES"
      data={totalBuyers || '-'}
      kind="info1"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faUsers} />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="Crear Cliente"
      function={() => history.push('buyers/new')}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/group%20of%20persons.svg"
      style={styles.linkContainer}
      width={width}
      access={access}
      route={resourceRoute}
      action={sellerActions.create}
    />,
  ];

  const onCopy = () => {
    copyToClipboard(portalLink);
    enqueueSnackbar('Link copiado', { variant: 'info' });
  };

  return (
    <Fragment>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <FontAwesomeIcon icon={faUser} />
          <span className={styles.startTitle}>Clientes</span>
        </Typography>
        <Typography variant="body1" mt={2}>
          En esta sección puedes ver todos los clientes que tienes registrados en tu empresa. Puedes
          acceder al detalle de cada uno, donde podrás ver todos los servicios a los que está
          suscrito y ver todos los pagos que ha realizado.
        </Typography>
        {!company?.disabled_client_portal && (
          <Fragment>
            <Typography variant="body1" mt={1}>
              Link portal del cliente:{' '}
              <span style={{ fontSize: 16 }}>
                <b onClick={() => onCopy()}>{`${portalLink}`}</b>
              </span>
            </Typography>
            <Typography variant="body1">
              En este portal tus clientes pueden ver todos los servicios a los que está suscrito,
              configurar sus medios de pago inscritos y ver todos los pagos que ha realizado. El
              portal está disponible para todos tus clientes, y pueden acceder con el mismo correo
              que se suscribieron a tus productos/servicios.
            </Typography>
          </Fragment>
        )}
      </div>

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      {isMobile ? (
        <Button
          className={styles.toButton}
          onClick={() => history.push('recurrent/new')}
          variant="contained"
        >
          Crear Cliente
        </Button>
      ) : null}

      <ResourceList
        title="Clientes"
        queryFields="Nombre, correo"
        setResource={setBuyer}
        getResourceList={sellerApi.buyers.list}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.buyers.generateExcel(parentId, query, filterParams)
        }
        resourceParent={company}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'email', label: 'Correo' },
          { key: 'status', label: 'Estado' },
          { key: 'total_subscriptions', label: 'TotalSubscriptions' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'email' }, { key: 'status' }]}
        listColumns={{
          name: (res: Buyer) => (
            <div className={styles.nameContainer}>
              {!isMobile && <Avatar text={res.name} className={styles.avatar} />}
              <Typography variant="h6">
                <b>{capitalizeString(res.name)}</b>
              </Typography>
            </div>
          ),
          email: (res: Buyer) =>
            isMobile ? (
              <a href={`mailto:${res.email}`}>Enviar email</a>
            ) : (
              <a href={`mailto:${res.email}`}>{res.email}</a>
            ),
          status: (res: Buyer) =>
            res.has_indebt_services ? (
              <Chip label="Con Deuda" variant="filled" color="error" />
            ) : (
              <Chip label="Al Día" variant="filled" color="success" />
            ),
          total_subscriptions: (res: Buyer) =>
            `Tiene ${res.total_subscriptions} ${
              res.total_subscriptions == 1 ? 'servicio' : 'servicios'
            }`,
        }}
        listActionsHeaders={() => [{ key: 'show', label: 'Ver más' }]}
        listActions={{
          show: (res: Buyer) => {
            if (!allowedRole(access, resourceRoute, sellerActions.read)) {
              enqueueSnackbar('No tienes acceso a la sección que estás intentado acceder', {
                variant: 'error',
              });
              return null;
            }
            onItem(res);
            history.push(`/seller/buyers/${res.id}`);
          },
        }}
        alternativeStyle={styles.alternativeTable}
        msgOnError="Selecciona una suscripción o botón de pago para mostrar pagos"
        filtersKey="buyers_index"
        persistFilters
      />
    </Fragment>
  );
};

export default BuyersPage;
