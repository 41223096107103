import React, { Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../app/hooks';
import { useFormik, getIn, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

// API
import { sellerApi } from '../../../common/api';

// Features
import { SellerState } from '../sellerSlice';
import { Seller, User, CollectionCompany } from '../../../app/type';

// Components
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Drawer,
  TextField,
  Button,
  useMediaQuery,
  Switch,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Edit as EditIcon } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { setDocumentTitle } from '../../../common/utils';
import PopUp from '../../../common/components/PopUp';
import SellerSelectorForm from '../../../common/components/seller/SellerSelector';
import { CheckboxesTags } from '../../../common/components/AutocompleteCheckbox';

// Descriptions
import { ACCESS_DESCRIPTIONS } from '../../../common/constants/descriptions';

// Assets
import cStyles from '../../../common/styles/common.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import styles from './CompanySeller.module.scss';

interface SellerInfo extends Partial<Omit<Seller, 'user'>> {
  user?: Partial<User>;
}

const SellerSchema = yup.object().shape({
  user: yup.object().shape({
    email: yup.string().email('Correo del usuario es requerido').required(),
    name: yup.string().required('Nombre del usuario es requerido'),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, 'Deben ser solo dígitos')
      .min(9, 'Debe tener un largo exacto de 9 dígitos')
      .max(9, 'Debe tener un largo exacto de 9 dígitos'),
  }),
});

const SellersConfiguration = (): React.ReactElement => {
  setDocumentTitle('Configuración usuarios');
  const { company, loading } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [sellerMails, setSellerMails] = useState<any>();
  const [selectedSeller, setSelectedSeller] = useState<Seller>();
  const [selectedMail, setSelectedMail] = useState<string>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [mailDrawerOpen, setMailDrawerOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const reloadRef = useRef<{ reloadSellers: () => void }>();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const initialValues = {
    id: selectedSeller?.id || '',
    access: selectedSeller?.access || '',
    user: {
      name: selectedSeller?.user?.name || '',
      email: selectedSeller?.user?.email || '',
      phone: selectedSeller?.user?.phone || '',
    },
  } as SellerInfo;
  const [collectionCompanies, setCollectionCompanies] = useState<CollectionCompany[]>([]);
  const initialCollectionCompanies = {
    sellerId: selectedSeller?.id || '',
    restrictedCollectionCompanies: selectedSeller?.restricted_collection_companies || false,
    collectionCompanies: selectedSeller?.collection_companies?.map((company) => ({
      id: company.id || '',
      name: company.name || '',
    })) || [{ id: '', name: '' }],
  };

  const formik = useFormik<SellerInfo>({
    initialValues,
    validationSchema: SellerSchema,
    onSubmit: (sellerInfo: SellerInfo, { setErrors }: any) => {
      const request = sellerInfo.id ? sellerApi.sellers.update : sellerApi.sellers.create;
      request(company?.id || '', sellerInfo)
        .then(() => {
          enqueueSnackbar('Guardado', { variant: 'success' });
          reloadRef.current?.reloadSellers();
          formik.resetForm();
        })
        .catch((err: any) => {
          if (err?.response?.status === 400) {
            enqueueSnackbar('Ya existe un usuario con ese correo, intenta con otro', {
              variant: 'error',
            });
            setErrors(err.response);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => {
          setDrawerOpen(false);
        });
    },
    enableReinitialize: true,
  });

  const handleMailChange = (event: { target: { checked: boolean; id: string } }): void => {
    const updatedSellerMails = {
      ...sellerMails,
      [event.target.id]: {
        ...sellerMails[event.target.id],
        [selectedMail || '']: event.target.checked,
      },
    };
    setSellerMails(updatedSellerMails);
    event.target.checked;
  };

  const editSeller = (seller: Seller) => {
    setSelectedSeller(seller);
    setDrawerOpen(true);
  };

  const editMailSender = (mail: string) => {
    setSelectedMail(mail);
    setMailDrawerOpen(true);
  };

  const deleteSeller = () => {
    sellerApi.sellers
      .destroy(selectedSeller?.id)
      .then(() => {
        enqueueSnackbar('Usuario eliminado', { variant: 'success' });
        reloadRef.current?.reloadSellers();
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, por favor inténtalo nuevamente', {
          variant: 'error',
        });
      })
      .finally(() => setDeleteOpen(false));
  };

  const closeMailsDrawer = () => {
    updateSellerMails();
    setMailDrawerOpen(false);
    setSelectedMail(undefined);
  };

  const updateSellerMails = () => {
    const hash = sellers.reduce((acc, item) => ({ ...acc, [item.id]: item.mails }), {});
    setSellerMails(hash);
  };

  const submitSellerMails = () => {
    sellerApi.sellers
      .updateMails(company?.id || '', sellerMails)
      .then(() => {
        enqueueSnackbar('Guardado', { variant: 'success' });
        reloadRef.current?.reloadSellers();
        closeMailsDrawer();
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
      });
  };

  useEffect(() => {
    if (sellers) {
      updateSellerMails();
    }
  }, [sellers]);

  useImperativeHandle(reloadRef, () => ({
    reloadSellers() {
      if (company) {
        sellerApi.sellers.list(company.id).then((data) => setSellers(data.data));
      }
    },
  }));

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else if (company) {
      sellerApi.sellers.list(company.id).then((data) => setSellers(data.data));
    }
  }, [company?.id]);

  useEffect(() => {
    drawerOpen !== true && setSelectedSeller(undefined);
  }, [drawerOpen]);

  useEffect(() => {
    if (company?.id) {
      sellerApi.companies.collectionCompanies(company?.id).then((data) => {
        const collectionCompanies: CollectionCompany[] = data.data;
        setCollectionCompanies(collectionCompanies);
      });
    }
  }, [company?.id]);

  interface updateSellerCollectionInterface {
    sellerId: string;
    restrictedCollectionCompanies: boolean;
    collectionCompanies: {
      id: string;
      name: string;
    }[];
  }
  function updateSellerCollectionCompanies(values: updateSellerCollectionInterface) {
    const request = sellerApi.sellers.updateSellerCollectionCompanies;
    request(values.sellerId, values.restrictedCollectionCompanies, values.collectionCompanies)
      .then(() => {
        enqueueSnackbar('Guardado', { variant: 'success' });
        reloadRef.current?.reloadSellers();
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
      })
      .finally(() => {
        setDrawerOpen(false);
      });
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            <Grid container>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Usuarios</Typography>
                </div>
                <div className={cStyles.paperHeaderActions}>
                  <IconButton
                    size="medium"
                    color="primary"
                    disabled={loading}
                    onClick={() => setDrawerOpen(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </div>
              </Grid>
              {sellers.map((seller) => (
                <div key={seller.id} className={styles.informationContainer}>
                  <div className={styles.header}>
                    {isMobile ? (
                      <Fragment>
                        <Typography color="primary" variant="subtitle1">
                          Nombre: {seller.user?.name}
                        </Typography>
                        <Typography color="primary" variant="subtitle1">
                          Correo:{' '}
                          {seller.edited_email
                            ? seller.user?.unconfirmed_email
                            : seller.user?.email}{' '}
                        </Typography>
                        <Typography color="primary" variant="subtitle1">
                          Teléfono: {seller.user?.phone}
                        </Typography>
                        {seller.edited_email && (
                          <span>
                            <Typography sx={{ fontSize: '11px' }}>
                              (Esperando confirmación de cambio de correo)
                            </Typography>
                          </span>
                        )}
                      </Fragment>
                    ) : (
                      <Typography color="primary" variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
                        Nombre: {seller.user?.name} | Correo:{' '}
                        {seller.edited_email ? seller.user?.unconfirmed_email : seller.user?.email}{' '}
                        | Teléfono: {seller.user?.phone}{' '}
                        {seller.edited_email && (
                          <span>
                            <Typography sx={{ fontSize: '12px' }}>
                              (Esperando confirmación de cambio de correo)
                            </Typography>
                          </span>
                        )}{' '}
                      </Typography>
                    )}
                  </div>
                  <div className={styles.actions}>
                    <IconButton
                      size="medium"
                      color="primary"
                      disabled={loading}
                      onClick={() => editSeller(seller)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="medium"
                      color="error"
                      disabled={loading}
                      onClick={() => {
                        setDeleteOpen(true);
                        setSelectedSeller(seller);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </IconButton>
                  </div>
                </div>
              ))}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            <Grid container>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Envío de correos</Typography>
                </div>
              </Grid>
              <div className={styles.informationContainer}>
                <div>
                  <div className={styles.header}>
                    <Typography color="primary" variant="subtitle1">
                      Aviso de pago
                    </Typography>
                  </div>
                  <Typography color="primary">
                    Correo que se envía para notificar el pago realizado por un cliente.
                  </Typography>
                </div>
                <div className={styles.actions}>
                  <IconButton
                    size="medium"
                    color="primary"
                    disabled={loading}
                    onClick={() => editMailSender('notificate_correct_payment')}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
              <div className={styles.informationContainer}>
                <div>
                  <div className={styles.header}>
                    <Typography color="primary" variant="subtitle1">
                      Aviso de cliente en deuda
                    </Typography>
                  </div>
                  <Typography color="primary">
                    Correo que se envía para notificar que un cliente está en deuda con algún pago.
                  </Typography>
                </div>
                <div className={styles.actions}>
                  <IconButton
                    size="medium"
                    color="primary"
                    disabled={loading}
                    onClick={() => editMailSender('notificate_bad_subscription')}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
              <div className={styles.informationContainer}>
                <div>
                  <div className={styles.header}>
                    <Typography color="primary" variant="subtitle1">
                      Aviso de nueva suscripción
                    </Typography>
                  </div>
                  <Typography color="primary">
                    Correo que se envía para notificar que se creó una nueva suscripción.
                  </Typography>
                </div>
                <div className={styles.actions}>
                  <IconButton
                    size="medium"
                    color="primary"
                    disabled={loading}
                    onClick={() => editMailSender('notificate_new_subscription')}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
              {company?.has_shopify && (
                <div className={styles.informationContainer}>
                  <div>
                    <div className={styles.header}>
                      <Typography color="primary" variant="subtitle1">
                        Aviso problema de cobro en una suscripción
                      </Typography>
                    </div>
                    <Typography color="primary">
                      Correo que se envía para notificar que ocurrió un problema al intentar cobrar
                      una cuota de una suscripción por un problema en el pedido.
                    </Typography>
                  </div>
                  <div className={styles.actions}>
                    <IconButton
                      size="medium"
                      color="primary"
                      disabled={loading}
                      onClick={() => editMailSender('notificate_problem_on_charge')}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <form onSubmit={formik.handleSubmit} className={styles.drawerContainer}>
          <div className={styles.drawerField}>
            <Typography>Nombre*</Typography>
            <TextField
              required
              id="user.name"
              type="text"
              autoComplete="user.name"
              variant="outlined"
              value={formik.values.user?.name}
              onChange={formik.handleChange}
              error={
                getIn(formik.touched, 'user.name') && Boolean(getIn(formik.errors, 'user.name'))
              }
              helperText={getIn(formik.touched, 'user.name') && getIn(formik.errors, 'user.name')}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Correo*</Typography>
            <TextField
              required
              id="user.email"
              type="text"
              autoComplete="user.email"
              variant="outlined"
              value={formik.values.user?.email}
              onChange={formik.handleChange}
              error={
                getIn(formik.touched, 'user.email') && Boolean(getIn(formik.errors, 'user.email'))
              }
              helperText={getIn(formik.touched, 'user.email') && getIn(formik.errors, 'user.email')}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Teléfono</Typography>
            <TextField
              id="user.phone"
              type="text"
              autoComplete="user.phone"
              variant="outlined"
              value={formik.values.user?.phone}
              onChange={formik.handleChange}
              error={
                getIn(formik.touched, 'user.phone') && Boolean(getIn(formik.errors, 'user.phone'))
              }
              helperText={getIn(formik.touched, 'user.phone') && getIn(formik.errors, 'user.phone')}
            />
          </div>
          <div className={styles.drawerField}>
            <SellerSelectorForm formik={formik} />
          </div>
          <div className={styles.drawerField}>
            {formik.values.access && (
              <Typography variant="caption">
                {
                  ACCESS_DESCRIPTIONS[formik.values.access as keyof typeof ACCESS_DESCRIPTIONS]
                    .description
                }
              </Typography>
            )}
          </div>
          <div className={styles.actions}>
            <Button variant="outlined" onClick={() => setDrawerOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Guardar
            </Button>
          </div>
        </form>
        {collectionCompanies.length > 0 && initialCollectionCompanies.sellerId && (
          <div className={styles.drawerContainer}>
            <div className={styles.drawerField}>
              <div style={{ width: '100%', justifyContent: 'center' }}>
                <Formik
                  initialValues={initialCollectionCompanies}
                  onSubmit={(values) => {
                    if (initialCollectionCompanies.sellerId === '') {
                      enqueueSnackbar('Debe crear al usuario primero', { variant: 'error' });
                      return;
                    }
                    updateSellerCollectionCompanies({
                      sellerId: values.sellerId,
                      restrictedCollectionCompanies: values.restrictedCollectionCompanies,
                      collectionCompanies: values.collectionCompanies,
                    });
                  }}
                >
                  {(props: any) => (
                    <form onSubmit={props.handleSubmit}>
                      <div className={styles.drawerField}>
                        <FormGroup>
                          <FormControlLabel
                            label="¿Limitar vistas a proyectos?"
                            control={
                              <Checkbox
                                id="restrictedCollectionCompanies"
                                checked={props.values?.restrictedCollectionCompanies}
                                onChange={props.handleChange}
                              />
                            }
                          />
                        </FormGroup>
                      </div>

                      {props.values?.restrictedCollectionCompanies && (
                        <CheckboxesTags
                          fieldName="collectionCompanies"
                          displayArray={collectionCompanies}
                          label={'Proyectos visibles'}
                          placeholder={'Seleccione los proyectos'}
                          disabled={false}
                          selectedIds={selectedSeller?.collection_companies?.map((cc) => cc.id)}
                        />
                      )}

                      <div className={styles.centeredActions}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            props.resetForm();
                            setDrawerOpen(false);
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button variant="contained" type="submit">
                          {props.values?.restrictedCollectionCompanies ? 'Limitar' : 'Guardar'}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </Drawer>
      <Drawer anchor="right" open={mailDrawerOpen} onClose={() => closeMailsDrawer()}>
        <div className={styles.drawerContainer}>
          <Typography sx={{ margin: '30px 0' }}>
            Usuarios a quienes se les envía el correo.
          </Typography>
          {selectedMail && (
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {sellers.map((s: Seller) => (
                  <div key={s.id}>
                    <FormControlLabel
                      control={
                        <Switch
                          id={s.id}
                          checked={sellerMails?.[s.id][selectedMail as string]}
                          onChange={(event) => handleMailChange(event)}
                          name={s.id}
                        />
                      }
                      label={s.user?.name}
                    />
                  </div>
                ))}
              </FormGroup>
            </FormControl>
          )}
          <div className={styles.actions} style={{ marginTop: '50px' }}>
            <Button variant="outlined" onClick={() => closeMailsDrawer()}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => submitSellerMails()}>
              Guardar
            </Button>
          </div>
        </div>
      </Drawer>
      <PopUp
        content={
          <Typography>
            ¿Estás seguro que quieres eliminar este usuario? No se puede deshacer esta acción
          </Typography>
        }
        state={{ open: deleteOpen, setOpen: setDeleteOpen }}
        extraActions={[
          <Button key={1} variant="outlined" color="primary" onClick={() => setDeleteOpen(false)}>
            Cancelar
          </Button>,
          <Button key={2} variant="contained" color="error" onClick={() => deleteSeller()}>
            Eliminar
          </Button>,
        ]}
      />
    </Fragment>
  );
};

export default SellersConfiguration;
