import client from '../../client';

interface RootResponse {
  redirect_uri?: string;
}

interface CallbackResponse {
  message: string;
  redirect_uri?: string;
}

interface StoreCredentialResponse {
  message: boolean;
  redirect_uri?: string;
}

export const rootRoute = (query: string): Promise<any> => {
  return client({
    method: 'get',
    url: `/v1/public/jumpseller/jumpseller_applications/root?${query}`,
  })
    .then(({ data: body }: { data: RootResponse }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
export const callbackRoute = (query: string): Promise<any> => {
  return client({
    method: 'get',
    url: `/v1/public/jumpseller/jumpseller_applications/callback?${query}`,
  })
    .then(({ data: body }: { data: CallbackResponse }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const checkStoreCredential = (storeId: string): Promise<StoreCredentialResponse> => {
  return client({
    method: 'get',
    url: `/v1/public/jumpseller/jumpseller_applications/check_store_credential?store_id=${storeId}`,
  })
    .then(({ data: body }: { data: StoreCredentialResponse }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
