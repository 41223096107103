import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tab,
  useMediaQuery,
  Box,
  CircularProgress,
  TableHead,
  Checkbox,
  Grid,
  Tooltip,
} from '@mui/material';
import { useSelector } from '../../../app/hooks';
import { ClientState } from '../clientSlice';
import { clientApi } from '../../../common/api';
import { formatCurrency } from '../../../common/utils';
import { ApiList, ApiPagination, Statement } from '../../../app/type';
import Pagination from '../../../common/components/Pagination';
import StatusLabel from '../../../common/components/StatusLabel';
import { STATUS_COLORS } from '../../../common/constants/statements';

import styles from '../products/Product.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { useShopCartContext } from '../../../common/contexts/ShopCart';

interface StatementsTableProps {
  isPaymentPlan?: boolean;
  productId: string;
}

export const StatementsTable = ({
  isPaymentPlan,
  productId,
}: StatementsTableProps): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [futureStatements, setFutureStatements] = useState<Statement[]>();
  const [paidStatements, setPaidStatements] = useState<Statement[]>();
  const [statements, setStatements] = useState<Statement[]>();
  const [statementsLoading, setStatementsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<ApiPagination>({ current: 1, pages: 1 });
  const [futurePage, setFuturePage] = useState<ApiPagination>({ current: 1, pages: 1 });
  const [paidPage, setPaidPage] = useState<ApiPagination>({ current: 1, pages: 1 });

  const history = useHistory();
  const { client, company } = useSelector(({ client }: { client: ClientState }) => client);
  const { statementsCart, removeStatementsCart, addStatementsCart } = useShopCartContext();
  const productRequest = isPaymentPlan ? clientApi.paymentPlans : clientApi.subscriptionBuyers;

  const loadResource = (currentPage: number) => {
    setStatementsLoading(true);
    const request =
      selectedTab === 0 ? productRequest.futureStatements : productRequest.paidStatements;
    request(productId, client?.id || '', currentPage)
      .then((data: ApiList<Statement>) => {
        if (selectedTab === 0) {
          setFutureStatements(data.data);
          setFuturePage(data.meta);
          setStatements(data.data);
        } else {
          setPaidStatements(data.data);
          setPaidPage(data.meta);
          setStatements(data.data);
        }

        if (data.meta) {
          if (page.current > data.meta.pages) {
            setPage({ ...data.meta, current: 1 });
          } else {
            setPage(data.meta);
          }
        }
      })
      .catch(console.error)
      .finally(() => {
        setStatementsLoading(false);
      });
  };

  const handleTabChange = (tab: number) => {
    if (tab === 0) {
      setStatements(futureStatements);
      setPage(futurePage);
      setSelectedTab(0);
    } else {
      setStatements(paidStatements);
      setPage(paidPage);
      setSelectedTab(1);
    }
  };

  const handleCheckbox = (statement: Statement) => {
    if (!statement) return;
    if (statementsCart.includes(statement.id)) {
      removeStatementsCart(statement);
    } else {
      addStatementsCart(statement);
    }
  };

  useEffect(() => {
    setStatementsLoading(true);
    const queries: Promise<any>[] = [new Promise((resolve) => setTimeout(resolve, 1000))];
    const futureStatementsQuery = productRequest
      .futureStatements(productId, client?.id || '')
      .then((data: ApiList<Statement>) => {
        setFutureStatements(data.data);
        setPage(data.meta);
        setFuturePage(data.meta);
      })
      .catch(() => {
        history.replace('/client/error');
      });
    const paidStatementsQuery = productRequest
      .paidStatements(productId, client?.id || '')
      .then((data: ApiList<Statement>) => {
        setPaidStatements(data.data);
        setPaidPage(data.meta);
      })
      .catch(() => {
        history.replace('/client/error');
      });
    queries.push(futureStatementsQuery);
    queries.push(paidStatementsQuery);
    Promise.all(queries).finally(() => {
      setStatementsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (futureStatements && selectedTab === 0) {
      setStatements(futureStatements);
    }
  }, [futureStatements]);

  return (
    <div>
      <Tabs
        className={styles.tabs}
        value={selectedTab}
        onChange={(_, value) => {
          handleTabChange(value);
        }}
      >
        <Tab
          label="Cuotas por pagar"
          style={{
            color:
              selectedTab === 0
                ? company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3'
                : '#707070',
          }}
        />
        <Tab
          label="Cuotas pagadas"
          style={{
            color:
              selectedTab === 1
                ? company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3'
                : '#707070',
          }}
        />
      </Tabs>
      {isMobile && (
        <Grid
          container
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          {selectedTab === 0 ? (
            <Fragment>
              <Grid item className={styles.gridRowCenter}>
                <div style={{ backgroundColor: variables.error }} className={styles.chip}></div>
                <Typography variant={'body1'}>En deuda</Typography>
              </Grid>
              <Grid item className={styles.gridRowCenter}>
                <div style={{ backgroundColor: variables.pending }} className={styles.chip}></div>
                <Typography variant={'body1'}>Por cobrar</Typography>
              </Grid>
            </Fragment>
          ) : (
            <Grid item className={styles.gridRowCenter}>
              <div style={{ backgroundColor: variables.success }} className={styles.chip}></div>
              <Typography variant={'body1'}>Pagada</Typography>
            </Grid>
          )}
        </Grid>
      )}
      {statementsLoading ? (
        <div className={styles.spinnerContainer}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              sx={{
                color: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            />
          </Box>
        </div>
      ) : (
        <Fragment>
          <div className={styles.table}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow className={styles.tableHeader}>
                  {selectedTab === 0 && <TableCell></TableCell>}
                  {isPaymentPlan && <TableCell className={styles.rowElement}>Cuota</TableCell>}
                  {isPaymentPlan && (
                    <TableCell className={styles.rowElement}>Nombre cuota</TableCell>
                  )}
                  <TableCell className={styles.rowElement}>Monto</TableCell>
                  <TableCell className={styles.rowElement}>
                    {selectedTab === 0 ? 'Vencimiento' : 'Fecha de pago'}
                  </TableCell>
                  {!isMobile && <TableCell className={styles.rowElement}>Estado</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {statements?.map((statement: Statement, index: number) => (
                  <TableRow
                    key={statement.id}
                    className={`${index === statements.length - 1 ? styles.hideDivider : ''}`}
                  >
                    {selectedTab === 0 && (
                      <TableCell>
                        <Checkbox
                          checked={statementsCart.includes(statement.id || '')}
                          onChange={() => handleCheckbox(statement)}
                          style={{
                            color: statementsCart.includes(statement.id || '')
                              ? company?.payment_design?.background_color
                                ? company?.payment_design?.background_color
                                : '#4653e3'
                              : 'inherit',
                          }}
                        />
                      </TableCell>
                    )}
                    {isPaymentPlan && (
                      <TableCell className={styles.rowElement}>
                        <Typography>{statement.installment_number}</Typography>
                      </TableCell>
                    )}
                    {isPaymentPlan && (
                      <TableCell className={styles.truncateDiv}>
                        {statement.name ? (
                          <Tooltip title={statement.name} arrow>
                            <Typography className={styles.truncateText}>
                              {statement.name}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography>{'-'}</Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell className={styles.rowElement}>
                      <Typography>
                        {selectedTab === 0
                          ? statement.currency === 'UF'
                            ? formatCurrency(statement.external_amount, statement.currency)
                            : formatCurrency(statement.amount)
                          : `${formatCurrency(statement.amount)} ${
                              statement.currency === 'UF'
                                ? ` (${formatCurrency(
                                    statement.external_amount,
                                    statement.currency
                                  )})`
                                : ''
                            }`}
                      </Typography>
                    </TableCell>
                    <TableCell className={`${styles.rowElement}`}>
                      <div
                        className={styles.dateStatus}
                        style={{
                          backgroundColor: isMobile ? STATUS_COLORS[statement.status] : 'unset',
                        }}
                      >
                        <Typography>
                          {selectedTab === 0
                            ? statement.due_date.replace(/\//g, '-')
                            : statement.payment_date.replace(
                                /^(\d{4})-(\d{2})-(\d{2})$/,
                                '$3-$2-$1'
                              )}
                        </Typography>
                      </div>
                    </TableCell>
                    {!isMobile && (
                      <TableCell className={styles.rowElement}>
                        <StatusLabel type="statement" status={statement.status} client={true} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className={styles.tablePagination}>
            <Pagination
              page={page}
              onChange={(current: number) => {
                setPage((p) => ({ ...p, current }));
                loadResource(current);
              }}
              disabled={statementsLoading}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default StatementsTable;
