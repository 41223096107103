import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SellerState } from '../sellerSlice';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import styles from './Index.module.scss';
import Boxes from './Boxes';
import Transfers from './Transfers';
import Payments from './Payments';
import Invoices from './Invoices';

const Index = (): React.ReactElement => {
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [tableToShow, setTable] = useState<string>('payments');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTable(newValue);
  };

  return (
    <div className={styles.mainContainer}>
      <Box sx={{ width: '100%' }} className={styles.tabsBox}>
        <Tabs value={tableToShow} onChange={handleChange}>
          <Tab value="payments" label="Pagos" />
          <Tab value="transfers" label="Transferencias" />
          <Tab value="invoices" label="Facturas" />
          {/* <Tab value="refunds" label="Devoluciones" /> */}
        </Tabs>
      </Box>
      <div className={styles.boxsContainer}>
        {<Boxes context={tableToShow} company={company} />}
      </div>
      {tableToShow === 'transfers' && <Transfers company={company} />}
      {tableToShow === 'payments' && <Payments company={company} />}
      {tableToShow === 'invoices' && <Invoices company={company} />}
      {/* {tableToShow === 'refunds' && <Refunds company={company} />} */}
    </div>
  );
};

export default Index;
