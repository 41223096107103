import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../app/hooks';
import { SellerState } from '../sellerSlice';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { generateImage } from '../../../common/utils/cropImage';

// Components
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
} from '@mui/material';
import { useFormik } from 'formik';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import PreviewBox from '../../../common/components/PreviewPaymentView';
import { useSnackbar } from 'notistack';
import { sellerApi } from '../../../common/api';

// Assets
import styles from './CompanySeller.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { PAYMENT_METHODS } from '../../../common/constants/company';
import { setDocumentTitle } from '../../../common/utils';

interface PaymentDesignInfo {
  id: string;
  background_color: string;
  company_id: string;
  show_image: boolean;
  custom_image?: string;
}

const PaymentLinksEdit = (): React.ReactElement => {
  setDocumentTitle('Configuración links de pago');
  const { loading, company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const [paymentMethodsChosen, setPaymentMethodsChosen] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const formik = useFormik<PaymentDesignInfo>({
    initialValues: company?.payment_design || {
      id: '',
      background_color: '#4653E3',
      company_id: company?.id || '',
      show_image: true,
      custom_image: '',
    },
    onSubmit: (paymentDesignInfo: PaymentDesignInfo, { setErrors }: any) => {
      const request = company?.payment_design?.id
        ? sellerApi.payment_designs.update
        : sellerApi.payment_designs.create;

      request({ data: paymentDesignInfo })
        .then()
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        });
      sellerApi.paymentMethods
        .update(company?.id || '', paymentMethodsChosen)
        .then((data) => {
          setPaymentMethodsChosen(data.data.map((pm) => pm.kind));
          enqueueSnackbar('Información actualizada correctamente', { variant: 'success' });
        })
        .catch((err: any) => {
          console.error(err);
        });
    },
  });

  const [urlImage, setUrlImage] = useState<string>(formik.values.custom_image || '');

  const handlePaymentMethodChange = (payment_method: string) => {
    if (paymentMethodsChosen?.includes(payment_method)) {
      setPaymentMethodsChosen(paymentMethodsChosen.filter((pm: string) => pm !== payment_method));
    } else {
      setPaymentMethodsChosen([...paymentMethodsChosen, payment_method]);
    }
  };

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setDialogOpen(true);
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };
                setUrlImage(URL.createObjectURL(blob));
                newImg.src = url;
                formik.setFieldValue('image_file', blob);
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else if (company) {
      sellerApi.paymentMethods.list(company.id).then((data) => {
        setPaymentMethodsChosen(data.data.map((pm) => pm.kind));
      });
    }
  }, [company?.id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper className={styles.infoPaper}>
            <Grid container spacing={3} className={styles.mainContainer}>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Estilo</Typography>
                  <Typography>
                    Puedes definir un color para personalizar el link de pago de tus servicios.
                  </Typography>
                </div>
              </Grid>
              <div className={styles.components}>
                <Grid item container xs={3} className={styles.companyDesignColors}>
                  <div>
                    <Typography mt={1}>
                      <li>Color empresa:</li>
                    </Typography>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      id="background_color"
                      type="color"
                      variant="outlined"
                      value={formik.values.background_color}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={styles.imageSettings}>
                    <Typography mt={1}>
                      <li>Mostrar imagen en link de pago:</li>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.show_image}
                          onChange={(e) => formik.setFieldValue('show_image', e.target.checked)}
                          name="show_image"
                        />
                      }
                      label="Mostrar imagen"
                    />

                    {formik.values.show_image && (
                      <div className={styles.columnDisplay}>
                        <Button
                          className={styles.buttonProductImage}
                          component="label"
                          startIcon={<CameraAltOutlinedIcon />}
                        >
                          Subir imagen
                          <input type="file" accept="image/*" hidden onChange={onUploadImage} />
                        </Button>
                        <Typography fontSize={12}>
                          *Si un servicio tiene otra imagen, esa imagen prevalecerá sobre la imagen
                          que definas acá.
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={9} className={styles.previewBoxContainer}>
                  <div className={styles.responsiveIframe}>
                    <PreviewBox
                      background_color={formik.values.background_color}
                      company={company}
                      showImage={formik.values.show_image}
                      previewImage={urlImage || company?.payment_design?.custom_image}
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
            {!company?.has_payment_credentials && (
              <Grid container className={styles.paymentMethodsContainer}>
                <Grid item xs={12} className={cStyles.paperHeader}>
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant="h5">Medios de pago</Typography>
                  </div>
                </Grid>
                <Typography>
                  Estos son los medios de pago con los cuales tus clientes pueden pagar tus
                  servicios.
                </Typography>
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    {Object.keys(PAYMENT_METHODS).map((payment_method: any) => (
                      <div key={payment_method}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={paymentMethodsChosen?.includes(payment_method)}
                              onChange={() => handlePaymentMethodChange(payment_method)}
                              name={payment_method}
                            />
                          }
                          label={PAYMENT_METHODS[payment_method]}
                        />
                      </div>
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} mt={2} display="flex" justifyContent="center" alignItems="center">
              <Button
                disableElevation
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                className="loader"
              >
                {loading && <CircularProgress size={20} />}
                Guardar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(false)}>
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle>Ajusta la imagen por favor</DialogTitle>
          </Grid>
          <Grid item xs={12} className={styles.dialogContainer}>
            <DialogContent>
              <div style={{ position: 'relative', width: '100%', height: 250 }}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="contain"
                />
              </div>
              <div style={{ position: 'relative', width: '90%' }}>
                <Slider
                  value={zoom}
                  min={1}
                  max={2}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: 'slider' }}
                />
              </div>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>Cancelar</Button>
              <Button onClick={acceptImage}>Aceptar</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </form>
  );
};

export default PaymentLinksEdit;
