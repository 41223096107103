import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Assets
import mainTheme from '../common/styles/theme';

// Features
import Login from '../features/session/Login';
import Logout from '../features/session/Logout';
import Register from '../features/session/Register';
import Recovery from '../features/session/Recovery';
import CreatePassword from '../features/session/CreatePassword';
import Seller from '../features/seller/Seller';
import SellerSetup from '../features/seller/company/Setup';
import Payment from '../features/buyer/Payment';
import Subscription from '../features/buyer/Subscription';
import UpdatePayment from '../features/buyer/UpdatePayment';
import Backoffice from '../features/backoffice/Backoffice';
import Confirmation from '../features/session/Confirmation';
import Client from '../features/client/Client';
import Jumpseller from '../features/applications/jumpseller/jumpseller';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TagManager from 'react-gtm-module';
import LogRocket from 'logrocket';

// Components
import {
  BackofficeRoute,
  PrivateRoute,
  PublicRoute,
  PublicBackofficeRoute,
  ClientRoute,
} from '../common/components/Routes';
import SnackbarCloseButton from '../common/components/CloseSnackbarButton';
import BackofficeLogin from '../features/session/BackofficeLogin';
import NotifyProvider from '../common/contexts/Notify';
import ClientLogin from '../features/client/ClientLogin';
import BillsPortal from '../features/bills/BillsPortal';

const GOOGLE_OAUTH_ID =
  process.env.NODE_ENV === 'production'
    ? '882583397925-7n9jm9s04iq3dqv5cld37eh9380b42m8.apps.googleusercontent.com'
    : '882583397925-6m5qbjsr3fvdr93i44kq5or3n5vt4raa.apps.googleusercontent.com';

const App = (): React.ReactElement => {
  if (process.env.NODE_ENV === 'production') {
    useEffect(() => {
      const tagManagerArgs = { gtmId: 'GTM-TNKGMHP' };
      TagManager.initialize(tagManagerArgs);
    }, []);
  }

  if (process.env.NODE_ENV === 'production') LogRocket.init('atwpif/onlypays');

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_ID}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Router>
          <SnackbarProvider
            preventDuplicate
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            action={(snackbarKey: number) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            autoHideDuration={8000}
          >
            <NotifyProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mainTheme}>
                  <Switch>
                    {/* Seller Section */}
                    <PrivateRoute path="/seller-setup">
                      <SellerSetup />
                    </PrivateRoute>

                    <PrivateRoute path="/seller">
                      <Seller />
                    </PrivateRoute>

                    <Route path="/auth/confirm-email">
                      <Confirmation />
                    </Route>

                    {/* Basic Payments Section */}
                    <Route path="/paga-tu-cuenta">
                      <BillsPortal />
                    </Route>

                    {/* Client Section */}
                    <Route path="/client/login">
                      <ClientLogin />
                    </Route>

                    <ClientRoute path="/client">
                      <Client />
                    </ClientRoute>

                    {/* Sessions Section */}
                    <PublicRoute path="/auth">
                      {/* Login Page */}
                      <Route path="/auth/login">
                        <Login />
                      </Route>

                      {/* Registration Page */}
                      <Route path="/auth/signup">
                        <Register />
                      </Route>

                      {/* Registration Page */}
                      <Route path="/auth/recovery/:token?">
                        <Recovery />
                      </Route>

                      <Route path="/auth/create_password/:token">
                        <CreatePassword />
                      </Route>

                      {/* NoMatch Page */}
                    </PublicRoute>

                    {/* Product Page */}
                    <Route path="/groups/:groupId">
                      <Payment />
                    </Route>
                    <Route path="/products/:companyId/:productId">
                      <Payment />
                    </Route>
                    <Route path="/products/:productId">
                      <Payment />
                    </Route>
                    <Route path="/subscriptions/:companyName/:subscriptionId">
                      <Payment />
                    </Route>
                    <Route path="/subscriptions/:subscriptionId">
                      <Payment />
                    </Route>
                    <Route path="/single_payments/:companyName/:singlePaymentId">
                      <Payment />
                    </Route>
                    <Route path="/single_payments/:singlePaymentId">
                      <Payment />
                    </Route>
                    <Route path="/payment_plans/:companyName/:paymentPlanId">
                      <Payment />
                    </Route>
                    <Route path="/payment_plans/:paymentPlanId">
                      <Payment />
                    </Route>
                    <Route path="/subscription_buyers/:companyName/:subscriptionBuyerId">
                      <Payment />
                    </Route>
                    <Route path="/subscription_buyers/:subscriptionBuyerId">
                      <Payment />
                    </Route>
                    <Route path="/single_payment_buyers/:companyName/:singlePaymentBuyerId">
                      <Payment />
                    </Route>
                    <Route path="/single_payment_buyers/:singlePaymentBuyerId">
                      <Payment />
                    </Route>
                    <Route path="/checkouts/:checkoutId">
                      <Payment />
                    </Route>
                    {/* Subscription Page */}
                    <Route path="/subscription">
                      <Subscription />
                    </Route>

                    {/* UpdatePayment Page */}
                    <Route path="/update-payment/:payableType/:payableId">
                      <UpdatePayment />
                    </Route>

                    {/* Backoffice */}
                    <PublicBackofficeRoute path="/backoffice/login">
                      <BackofficeLogin />
                    </PublicBackofficeRoute>

                    <BackofficeRoute path="/backoffice">
                      <Backoffice />
                    </BackofficeRoute>

                    {/* Jumpseller Application routes */}
                    <Route path="/jumpseller">
                      <Jumpseller />
                    </Route>

                    {/* Logout lobby */}
                    <Route path="/logout">
                      <Logout />
                    </Route>

                    {/* 404 */}
                    <PublicRoute path="*">
                      <Redirect to="/auth/login" />
                    </PublicRoute>
                  </Switch>
                </ThemeProvider>
              </StyledEngineProvider>
            </NotifyProvider>
          </SnackbarProvider>
        </Router>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
