import React from 'react';
import styles from './zafepayLogo.module.scss';

interface ZafepayLogoProps {
  color?: string;
  width?: string;
  redirectTo?: string | undefined;
}

const ZafepayLogo: React.FC<ZafepayLogoProps> = ({
  color = 'color',
  width = '350px',
  redirectTo = undefined,
}) => {
  const colors = ['color', 'white', 'grey'];
  if (!colors.includes(color)) {
    console.log('Invalid color, setting default color');
    color = 'color';
  }
  function redirect(redirectTo: string | undefined) {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }

  const imgClassName = `${styles.logo} ${redirectTo ? styles.pointer : ''}`;
  const src = `https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_${color}.png`;
  return (
    <div className={styles.logoContainer}>
      <img
        className={imgClassName}
        src={src}
        style={{ width: width }}
        alt="Zafepay Logo"
        onClick={() => redirect(redirectTo)}
      />
    </div>
  );
};

export default ZafepayLogo;
