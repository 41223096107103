import React from 'react';

// Assets
import styles from './Response.module.scss';

interface ResponseProps {
  loading: boolean;
  backgroundStyle: string;
  backgroundImgStyle?: string;
  icon: string;
  content: React.ReactElement;
  companyLogo?: string;
}

const Response = ({
  loading,
  backgroundStyle,
  backgroundImgStyle,
  icon,
  content,
  companyLogo,
}: ResponseProps): React.ReactElement => {
  return (
    <div
      className={`${styles.responseContainer} ${
        companyLogo ? styles.backgroundWhite : backgroundStyle
      }`}
    >
      {loading && (
        <div className={styles.loaderContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
            alt="Logo Zafepay"
          />
        </div>
      )}
      <div className={`${styles.response} ${backgroundImgStyle && backgroundImgStyle}`}>
        <div className={styles.responseContent}>
          <img
            src={companyLogo ? companyLogo : icon}
            alt="ícono respuesta"
            className={companyLogo ? styles.logo : styles.icon}
          />
          {content}
          <div className={styles.zpLogoContainer}>
            <a href="https://zafepay.com">
              <img
                src={`https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_${
                  companyLogo ? 'grey' : 'white'
                }.png`}
                alt="Logo Zafepay"
                className={styles.zpLogo}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Response;
