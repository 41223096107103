import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  List,
  ListItem,
  CircularProgress,
} from '@mui/material';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { useLocation } from 'react-router-dom';
import styles from './jumpseller.module.scss';

import { rootRoute, checkStoreCredential } from '../../../common/api/applications/jumpseller/app';
import ZafepayLogo from '../../../common/components/zafepayLogo';

const validationSchema = yup.object({
  storeName: yup.string().when('skiped', {
    is: false,
    then: yup.string().required('El nombre de la tienda es requerido'),
  }),
  contactMail: yup.string().when('skiped', {
    is: false,
    then: yup.string().email('El correo no es válido').required('El correo es requerido'),
  }),
  contactName: yup.string().when('skiped', {
    is: false,
    then: yup.string().required('El nombre de contacto es requerido'),
  }),
  skiped: yup.boolean(),
});

const Instructions: React.FC = () => {
  return (
    <Paper className={styles.mainPaper}>
      <Container className={styles.mainEditContainer}>
        <Typography variant="h5" align="center" className={styles.subtitle}>
          ¿Cómo tener suscripciones en tu tienda?
        </Typography>
        <Typography variant="body1" align="justify" className={styles.marginable}>
          ¡Solo necesitas ponerte en contacto con nosotros! contactes con{' '}
          <a href="mailto:hola@zafepay.com">hola@zafepay.com </a> o rellena el formulario de abajo
          para que podamos ayudarte a tener suscripciones en tu tienda.
        </Typography>
        <Typography variant="body1" align="justify">
          Nuestro equipo te contactará de vuelta lo antes posible para darte atención personalizada,
          enseñarte sobre las suscripciones con Zafepay y cómo integrarlas en tu tienda.
        </Typography>
        <div className={styles.marginable}>
          <Divider />
        </div>
        <Typography variant="h5" align="center" className={styles.subtitle}>
          ¿Cómo funciona el proceso?
        </Typography>
        <Typography variant="body1" align="justify">
          <List>
            <ListItem>
              1. Llena el formulario con tu nombre, tu email y el nombre de tu tienda.
            </ListItem>
            <ListItem>
              2. Haz clic en el botón &quot;Enviar&quot; y acepta los permisos para la aplicación.
              Guarda el código que te aparecerá en pantalla una vez aceptes los permisos.
            </ListItem>
            <ListItem>
              3. Nuestro equipo se pondrá en contacto contigo para darte atención personalizada.
            </ListItem>
            <ListItem>4. Te ayudaremos a crear una cuenta en Zafepay</ListItem>
            <ListItem>
              5. Uno de nuestros desarrolladores instalará un botón especial de suscripciones en tu
              tienda de Jumpseller. ¡Este botón podrás modificarlo como quieras!
            </ListItem>
            <ListItem>
              6. ¡Listo! Ya puedes empezar a vender suscripciones en tu tienda de Jumpseller.
            </ListItem>
          </List>
        </Typography>
        <div className={styles.marginable}>
          <Divider />
        </div>
        <Typography variant="h5" align="center" className={styles.subtitle}>
          ¿Cómo funciona Zafepay?
        </Typography>
        <Typography variant="subtitle1" align="justify" fontWeight="bold">
          Suscripción de tu cliente
        </Typography>
        <Typography variant="body1" align="justify">
          Zafepay es una plataforma de pagos que permite crear suscripciones. Cuando tus clientes
          quieran suscribirse a ciertos productos de tu tienda, harán click en un botón que los
          redirigirá a un link de pago de Zafepay. Allí, tus clientes eligirán la dirección de
          despacho, cada cuanto tiempo quieren recibir el pedido de forma automática y al intentar
          pagar enrolarán su tarjeta de preferencia. Una vez se confirme el pago, nosotros
          automáticamente enviaremos la orden a tu tienda de Jumpseller.
        </Typography>
        <Typography
          variant="subtitle1"
          align="justify"
          fontWeight="bold"
          className={styles.buttonMargin}
        >
          Compra automática
        </Typography>
        <Typography variant="body1" align="justify">
          Cuando llegue el momento de hacer el nuevo pedido, Zafepay automáticamente intentará
          cobrar a la tarjeta de tu cliente y creará la orden a tu tienda de Jumpseller. Se verá
          igual a como si hubieran hecho la compra desde tu tienda directamente. Tú no tienes que
          hacer nada más que enviar el pedido... ¡Así de fácil!
        </Typography>
      </Container>
    </Paper>
  );
};

const Form: React.FC = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const storeId = queryParams.get('store_id');
  const formik = useFormik({
    initialValues: {
      storeName: '',
      contactMail: '',
      contactName: '',
      storeId: storeId,
      skiped: false,
    },
    validationSchema,
    onSubmit: (values) => {
      rootRoute(
        `store_id=${values.storeId}&store_name=${values.storeName}&contact_mail=${values.contactMail}&contact_name=${values.contactName}&skiped=${values.skiped}`
      )
        .then((response) => {
          if (response.redirect_uri) {
            window.location.href = response.redirect_uri;
          } else {
            console.error({ showError: response, message: 'No redirect url in [JS]' });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error({ showError: error });
        });
    },
  });

  return (
    <Paper className={styles.mainPaper}>
      <Container className={styles.mainEditContainer}>
        <Typography variant="h5" align="center">
          Contacto
        </Typography>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Nombre de contacto
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="contactName"
                name="contactName"
                label="Nombre de contacto"
                variant="outlined"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                error={formik.touched.contactName && Boolean(formik.errors.contactName)}
                helperText={formik.touched.contactName && formik.errors.contactName}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Correo de contacto
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="contactMail"
                name="contactMail"
                label="Correo de contacto"
                variant="outlined"
                value={formik.values.contactMail}
                onChange={formik.handleChange}
                error={formik.touched.contactMail && Boolean(formik.errors.contactMail)}
                helperText={formik.touched.contactMail && formik.errors.contactMail}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Nombre de tu Tienda
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="storeName"
                name="storeName"
                label="Nombre de la Tienda"
                variant="outlined"
                value={formik.values.storeName}
                onChange={formik.handleChange}
                error={formik.touched.storeName && Boolean(formik.errors.storeName)}
                helperText={formik.touched.storeName && formik.errors.storeName}
              />
            </Grid>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              className={styles.buttonMargin}
            >
              Enviar
            </Button>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="button"
              className={styles.buttonMargin}
              onClick={() => {
                formik.setFieldValue('skiped', true);
                setTimeout(() => {
                  formik.handleSubmit();
                }, 50);
              }}
            >
              Avanzar sin contactar
            </Button>
          </Grid>
        </form>
        {loading && (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}
      </Container>
    </Paper>
  );
};

const Base: React.FC = () => {
  return (
    <Paper className={styles.mainPaper}>
      <Container className={styles.mainEditContainer}>
        <ZafepayLogo color="color" redirectTo="https://zafepay.com/" />
        <Instructions />
        <Form />
      </Container>
      <ZafepayLogo color="grey" width="150px" redirectTo="https://zafepay.com/" />
    </Paper>
  );
};

const Rejected: React.FC = () => {
  return (
    <Paper className={styles.mainPaper}>
      <div className={styles.iconContainer}>
        <BlockOutlinedIcon className={styles.principalIcon} />
      </div>
      <Typography variant="h4" align="center">
        Host is not from Jumpseller
      </Typography>
      <ZafepayLogo color={'grey'} width="200px" />
    </Paper>
  );
};

const Root: React.FC = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const storeId = queryParams.get('store_id');

  useEffect(() => {
    // No redirigir a la primera página si ya creó credenciales con ese storeId
    if (storeId) {
      checkStoreCredential(storeId)
        .then((response) => {
          if (response?.message === true) {
            if (response.redirect_uri) {
              window.location.href = response.redirect_uri;
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error({ showError: error });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [storeId]);

  if (isLoading) {
    return (
      <div>
        <div className={styles.loaderContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
            alt="Logo Zafepay"
          />
        </div>
      </div>
    );
  }
  return storeId ? <Base /> : <Rejected />;
};

export default Root;
