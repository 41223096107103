import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import { buyerApi } from '../../common/api';
import { redirect_buy_product, redirect_index_product } from '../../common/api/shopify/redirect';
import { redirect_jumpseller_checkout } from '../../common/api/jumpseller/redirect';
import { PaymentTransaction, Company, CompanyStyles } from '../../app/type';

// Utils
import { setDocumentTitle, useQuery, formatCurrency } from '../../common/utils';

// Components
import { Container, Grid, Typography, Button, Divider } from '@mui/material';
import Response from './components/Responses/Response';

// Assets
import styles from './Payment.module.scss';
import responseStyles from './components/Responses/Response.module.scss';
import variables from '../../common/styles/variables.module.scss';

const SubscriptionSuccess = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Pago Exitoso');
  const [payment, setPayment] = useState<PaymentTransaction>();
  const [loading, setLoading] = useState<boolean>(false);
  const query = useQuery();
  const text = (): string => {
    if (query?.inscription_only) return 'Tu tarjeta ha sido inscrita exitosamente.';
    else if (query?.delayed_wp)
      return 'Te has suscrito existosamente. Te enviaremos un correo cuando debas pagar la primera cuota.';
    return 'Tu pago fue exitoso';
  };

  useEffect(() => {
    if (query?.payment_id) {
      setLoading(true);
      buyerApi.payments
        .show(query.payment_id)
        .then((data) => {
          setPayment(data.data);
          if (data.data.status === 'rejected') {
            window.location.href = `/subscription/error`;
          }
        })
        .finally(() => setLoading(false));
    }
  }, [query]);

  return (
    <Grid container>
      <Response
        loading={loading || loadingStyles}
        backgroundStyle={responseStyles.blueBg}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/circle_check.svg'}
        companyLogo={companyStyles?.logo}
        content={
          payment ? (
            <div className={styles.paymentDetailContainer}>
              <Grid item container xs={12}>
                <Typography
                  variant="h2"
                  color={companyStyles ? companyStyles.color : 'white'}
                  margin="auto"
                  mb={2}
                >
                  ¡El pago realizado {companyStyles ? '' : `a ${payment.company.name}`} fue exitoso!
                </Typography>
              </Grid>
              <div
                className={styles.table}
                style={{ background: companyStyles ? 'white' : '#fafbff' }}
              >
                <div className={styles.title}>
                  <Typography
                    className={styles.title}
                    color={companyStyles ? `${companyStyles.color} !important` : '#4653E3'}
                  >
                    Detalles del pago
                  </Typography>
                </div>
                <div className={styles.info}>
                  <Typography className={styles.description}>Monto:</Typography>
                  <Typography className={styles.data}>{formatCurrency(payment.amount)}</Typography>
                </div>
                <Divider className={styles.divider} />
                <div className={styles.info}>
                  <Typography className={styles.description}>Fecha de pago:</Typography>
                  <Typography className={styles.data}>{payment.payment_date}</Typography>
                </div>
                <Divider className={styles.divider} />
                <div className={styles.info}>
                  <Typography className={styles.description}>Medio de pago:</Typography>
                  <Typography className={styles.data}>{payment.payment_method}</Typography>
                </div>
              </div>
            </div>
          ) : (
            <Grid item xs={12} className={styles.subscriptionRes}>
              <Typography
                variant="h4"
                mb={4}
                color={companyStyles ? `${companyStyles.color} !important` : 'white'}
              >
                ¡Muchas gracias!
              </Typography>
              <Typography
                component="div"
                variant="h6"
                color={companyStyles ? `#1f1f1f !important` : ''}
              >
                {text()}
              </Typography>
            </Grid>
          )
        }
      />
    </Grid>
  );
};

const SubscriptionError = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Pago No Realizado');
  const query = useQuery();
  const message =
    ' Esto puede deberse a diversas razones, te recomendamos verificar los detalles del pago o contactar a tu institución financiera para más información.';
  const text = (): string => {
    if (query?.inscription_only) return 'Lamentamos informarte que tu tarjeta ha sido rechazada.';
    return 'Lamentamos informarte que tu pago ha sido rechazado.';
  };
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.redBgLinear}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/warning_white.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Pago rechazado!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              <span className={responseStyles.strongText}>{text()}</span>
              {query.message ? (
                <Typography color={companyStyles ? `#1f1f1f !important` : 'white'}>
                  Motivo: {query.message}
                </Typography>
              ) : (
                <span
                  style={{ fontSize: '20px' }}
                  color={companyStyles ? `#1f1f1f !important` : 'white'}
                >
                  {message}
                </span>
              )}
            </Typography>
            {query.product_id && (
              <Button
                disableElevation
                size="medium"
                variant="contained"
                color="primary"
                sx={{ marginTop: '20px' }}
                component={Link}
                to={`/products/${query.product_id}`}
              >
                Volver a intentar
              </Button>
            )}
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionInactive = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Link Caducado');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.redBgRadial}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/plug.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Link expirado!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              <span className={responseStyles.strongText}>
                Lo sentimos, el enlace de pago ha caducado.
              </span>{' '}
              Si necesitas realizar un pago o completar una transacción, te recomendamos ponerte en
              contacto con {companyStyles ? `nosotros` : 'la persona o empresa'}.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionFailed = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Error de conexión');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.greenBg}
        backgroundImgStyle={responseStyles.responseBg2}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/sad_face.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Error al cargar el servicio!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              Lo sentimos, se produjo un{' '}
              <span className={responseStyles.strongText}>
                error al obtener los datos del servicio.{' '}
              </span>
              Por favor,{' '}
              <span className={responseStyles.strongText}>
                ponte en contacto con {companyStyles ? `un` : 'el'} vendedor
              </span>{' '}
              para resolver este problema.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionTerminated = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Servicio Inactivo');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.redBgLinear}
        backgroundImgStyle={responseStyles.responseBg3}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/box_error.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Producto no disponible!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              Lamentablemente, este{' '}
              <span className={responseStyles.strongText}>producto no está disponible</span> en este
              momento. Te recomendamos ponerte en contacto con {companyStyles ? `un` : 'el'}{' '}
              vendedor si quieres continuar con la compra.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const DebtPaid = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Sin Deudas 🎉');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.blueBg}
        backgroundImgStyle={responseStyles.responseBg4}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/claps.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Felicitaciones!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              <span className={responseStyles.strongText}>
                Has completado el pago de todas las cuotas que debías pagar al día de hoy 🎉
              </span>
            </Typography>
            <Typography
              mt={5}
              sx={{ fontWeight: '300', textAlign: 'center' }}
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              *Si consideras que hay algún error, por favor comunícate con{' '}
              {companyStyles ? `un` : 'el'} vendedor para resolverlo
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const BlockedCompany = (): React.ReactElement => {
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.greenBg}
        backgroundImgStyle={responseStyles.responseBg2}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/box_search.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Lo sentimos!
            </Typography>
            <Typography component="div" variant="h6">
              El vendedor{' '}
              <span className={responseStyles.strongText}>no puede proveer este servicio</span> en
              este momento. Ponte en contacto con el vendedor para resolver el problema.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionShopifySuccess = (): React.ReactElement => {
  setDocumentTitle('Suscripción Exitosa');

  const [company, setCompany] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(false);
  const query = useQuery();

  useEffect(() => {
    if (query?.company_id) {
      setLoading(true);
      buyerApi.companies
        .show(query.company_id)
        .then((data) => setCompany(data.data))
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <div className={`${styles.responseContainer}`}>
      {loading && (
        <div className={styles.loaderContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
            alt="Logo Zafepay"
          />
        </div>
      )}

      <div className={styles.zpLogoContainer}>
        <a href="https://zafepay.com">
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
            alt="Logo Zafepay"
            className={styles.zpLogo}
          />
        </a>
      </div>
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Grid container>
          <Grid item xs={12} className={styles.subscriptionRes}>
            {company && company.image ? (
              <img
                src={company.image}
                alt={`Logo ${company.name}`}
                className={styles.companyLogo}
              />
            ) : (
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/successful_payment.svg"
                alt="imagen éxito"
              />
            )}
            <Typography component="div">
              <p color={variables.error}>
                <strong>¡Felicitaciones!</strong>
              </p>
              Tu suscripción ha sido procesada de forma exitosa.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const ShopifyBuyLoading = (): React.ReactElement => {
  setDocumentTitle('Cargando suscripción');
  const location = useLocation();

  useEffect(() => {
    redirect_buy_product(location.search)
      .then((response: any) => {
        window.location.href = response.data.link;
      })
      .catch(() => {
        window.location.href = '/subscription/failed';
      });
  }, []);

  return (
    <Grid container>
      <div className={styles.loaderContainer}>
        <img
          src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
          alt="Logo Zafepay"
        />
      </div>
    </Grid>
  );
};

const ShopifyIndexLoading = (): React.ReactElement => {
  setDocumentTitle('Cargando suscripción');
  const location = useLocation();

  useEffect(() => {
    redirect_index_product(location.search)
      .then((response: any) => {
        window.location.href = response.data.link;
      })
      .catch(() => {
        window.location.href = '/subscription/failed';
      });
  }, []);

  return (
    <Grid container>
      <div className={styles.loaderContainer}>
        <img
          src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
          alt="Logo Zafepay"
        />
      </div>
    </Grid>
  );
};

const JumpsellerCheckout = (): React.ReactElement => {
  setDocumentTitle('Cargando suscripción');
  const location = useLocation();
  useEffect(() => {
    redirect_jumpseller_checkout(location.search)
      .then((response: any) => {
        window.location.href = response.redirect_url;
      })
      .catch((error: any) => {
        console.error(error);
        window.location.href = '/subscription/failed';
      });
  }, []);
  return (
    <Grid container>
      <div className={styles.loaderContainer}>
        <img
          src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
          alt="Logo Zafepay"
        />
      </div>
    </Grid>
  );
};

const OverStock = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Sin stock');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.yellowBg}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/no_stock_box.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Producto no disponible!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              Lamentamos informarte que este producto{' '}
              <span className={responseStyles.strongText}>no está disponible</span> en este momento.
              Por favor, ponte en contacto con {companyStyles ? `un` : 'el'} vendedor para resolver
              esta situación.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const InscriptionsLimitReached = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Inscripciones agotadas');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.yellowBg}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/no_stock_box.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Máximo de inscripciones alcanzado!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              Lamentamos informarte que{' '}
              <span className={responseStyles.strongText}>
                ya se alcanzó el límite de inscripciones de este servicio.
              </span>{' '}
              Por favor, ponte en contacto con {companyStyles ? `un` : 'el'} vendedor para resolver
              esta situación.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionCanceledSuccess = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Suscripción Cancelada');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.greenBg}
        backgroundImgStyle={responseStyles.responseBg5}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/smile_file.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Cancelación exitosa!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              Te informamos que tu suscripción ha sido cancelada con éxito.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const PendingSubscription = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Inscripción en proceso');
  const [textToShow, setTextToShow] = useState<string>('');
  const query = useQuery();

  useEffect(() => {
    if (['bt_bank_login', 'pending_add'].includes(query.status)) {
      setTextToShow(
        `Actualmente, el estado del pago se encuentra como "${query.message?.toLowerCase()}". Si el proceso continúa y la transferencia es aceptada, recibirás una notificación por correo electrónico.`
      );
    } else {
      setTextToShow(
        `Tu solicitud ha sido ingresada y está con estado "${
          query.message?.toLowerCase() || 'pendiente'
        }". El proceso de inscripción puede demorar hasta 48 horas hábiles, te notificaremos por correo electrónico cualquier actualización en la inscripción.`
      );
    }
  }, [query.status]);

  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.blueBg}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/wallet_clock.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              {query.inscription || !query.status
                ? '¡Procesando la inscripción!'
                : '¡Procesando el pago!'}
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              {textToShow}
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const PacInscriptionError = ({
  companyStyles,
  loadingStyles,
}: {
  companyStyles: CompanyStyles | undefined;
  loadingStyles: boolean;
}): React.ReactElement => {
  setDocumentTitle('Inscripción Fallida');
  return (
    <Grid container>
      <Response
        loading={loadingStyles}
        backgroundStyle={responseStyles.redBgLinear}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/warning_white.svg'}
        companyLogo={companyStyles?.logo}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography
              variant="h4"
              mb={4}
              color={companyStyles ? `${companyStyles.color} !important` : 'white'}
            >
              ¡Inscripción fallida!
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color={companyStyles ? `#1f1f1f !important` : 'white'}
            >
              <span className={responseStyles.strongText}>
                Lamentamos informarte que la inscripción del PAC ha fallado.
              </span>{' '}
              Esto puede deberse a diversas razones, te recomendamos contactar{' '}
              {companyStyles ? `a un` : 'al'} vendedor.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const ProcessingPayment = (): React.ReactElement => {
  setDocumentTitle('Procesando pago');
  const query = useQuery();

  useEffect(() => {
    if (query?.payable_id) {
      buyerApi.payments
        .payablePayment(query.payable_id, query.payable_type)
        .then((data) => {
          if (data.data.status === 'rejected') {
            window.location.href = `/subscription/error`;
          } else if (data.data.status === 'accepted') {
            window.location.href = `/subscription/success?payment_id=${data.data.uuid}`;
          } else {
            window.location.reload();
          }
        })
        .catch(() => {
          window.location.href = '/subscription/error';
        });
    } else if (query?.delayed_wp) {
      window.location.href = '/subscription/success?delayed_wp=true';
    }
  }, [query]);
  return (
    <Grid container>
      <Response loading={true} backgroundStyle={responseStyles.none} icon={''} content={<></>} />
    </Grid>
  );
};

const Subscription = (): React.ReactElement => {
  const [companyStyles, setCompanyStyles] = useState<CompanyStyles>();
  const [loadingStyles, setLoadingStyles] = useState<boolean>(true);

  const defaultHost =
    process.env.NODE_ENV === 'production'
      ? 'app.zafepay.com'
      : process.env.NODE_ENV === 'development'
      ? 'localhost'
      : 'staging.zafepay.com';

  useEffect(() => {
    if (window.location.hostname !== defaultHost) {
      setLoadingStyles(true);
      buyerApi.companies.publicStyles(window.location.hostname.split('.')[0]).then((data) => {
        if (data.data.logo) setCompanyStyles(data.data);
        setLoadingStyles(false);
      });
    } else {
      setLoadingStyles(false);
    }
  }, []);

  return (
    <Switch>
      <Route path="/subscription/success">
        <SubscriptionSuccess loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/shopify_success">
        <SubscriptionShopifySuccess />
      </Route>
      <Route path="/subscription/shopify_buy_loading">
        <ShopifyBuyLoading />
      </Route>
      <Route path="/subscription/shopify_index_loading">
        <ShopifyIndexLoading />
      </Route>
      <Route path="/subscription/jumpseller_checkout">
        <JumpsellerCheckout />
      </Route>
      <Route path="/subscription/error">
        <SubscriptionError loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/inactive">
        <SubscriptionInactive loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/failed">
        <SubscriptionFailed loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/terminated">
        <SubscriptionTerminated loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/debt_paid">
        <DebtPaid loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/blocked_company">
        <BlockedCompany />
      </Route>
      <Route path="/subscription/canceled_success">
        <SubscriptionCanceledSuccess loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/over_stock">
        <OverStock loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/inscriptions_limit_reached">
        <InscriptionsLimitReached loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/pac_failed">
        <PacInscriptionError loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/pending">
        <PendingSubscription loadingStyles={loadingStyles} companyStyles={companyStyles} />
      </Route>
      <Route path="/subscription/processing_payment">
        <ProcessingPayment />
      </Route>
    </Switch>
  );
};

export default Subscription;
