import { Area } from 'react-easy-crop/types';

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
    return image;
  });

const getCroppedImg = async (imageSrc: string, pixelCrop: Area, zoom: number) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return;
  }

  // Establecer las dimensiones del canvas al tamaño del recorte deseado
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Calcular el factor de escala entre las dimensiones naturales y las mostradas
  const scale = image.naturalWidth / image.width;

  // Ajustar las coordenadas y dimensiones según el zoom y la escala
  const sourceX = (pixelCrop.x * scale) / (zoom >= 1 ? 1 : zoom);
  const sourceY = (pixelCrop.y * scale) / (zoom >= 1 ? 1 : zoom);
  const sourceWidth = (pixelCrop.width * scale) / (zoom >= 1 ? 1 : zoom);
  const sourceHeight = (pixelCrop.height * scale) / (zoom >= 1 ? 1 : zoom);

  ctx.drawImage(
    image,
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return canvas;
};

export const generateImage = async (
  imageSrc: string,
  crop: Area,
  zoom?: number
): Promise<HTMLCanvasElement | undefined> => {
  if (!crop || !imageSrc) {
    return;
  }

  return await getCroppedImg(imageSrc, crop, zoom || 1);
};
