// React, packages and api
import React, { useRef, useEffect, useState, Fragment } from 'react';
import { sellerApi } from '../../../common/api';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Slice and utils
import { useSelector, useDispatch } from '../../../app/hooks';
import { SellerState, setSinglePayment } from '../sellerSlice';
import { formatCurrency, setDocumentTitle } from '../../../common/utils';
import { allowedRole, sellerActions } from '../../../common/utils/sellerAccess';

// MUI
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Styles and assets
import {
  faEye,
  faBellSlash,
  faBell,
  faTrashCan,
  faUpload,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import styles from './Index.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { Briefcase as BriefcaseIcon, Copy as CopyIcon } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Componentes
import InfoBox from '../../../common/components/InfoBox';
import Stepper from '../../../common/components/Stepper';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';

// Types
import { SingleDashboardData, SinglePayment } from '../../../app/type';

const SinglePaymentsIndex = (): React.ReactElement => {
  setDocumentTitle('Pagos Únicos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [sellerData, setSellerdata] = useState<SingleDashboardData>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const reloadRef = useRef<{ reloadResource: () => void }>();
  const [activateDialogOpen, setActivateDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [singlePaymentRes, setSinglePaymentRes] = useState<SinglePayment | null>(null);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [reportsDialogOpen, setReportsDialogOpen] = useState<boolean>(false);
  const [massiveSubscriptionsPopup, setMassiveSubscriptionsPopup] = useState<boolean>(false);
  const [massiveSubscriptionBuyersPopup, setMassiveSubscriptionBuyersPopup] =
    useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const access: string = company?.seller?.access || '';
  const resourceRoute = 'single_payment';

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else {
      sellerApi.companies
        .singleDashboard(company.id)
        .then((data) => {
          setSellerdata(data.data);
        })
        .catch(console.error);
    }
  }, [company]);

  const softDelete = (res: SinglePayment | null) => {
    if (res) {
      sellerApi.singlePayments
        .destroySinglePayment(res?.id)
        .then(() => {
          enqueueSnackbar('Pago único eliminado', { variant: 'success' });
          reloadRef?.current?.reloadResource?.();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
              variant: 'error',
            });
          } else if (error.response.status === 400) {
            enqueueSnackbar('El pago único no puede ser eliminado por tener pagos completados', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrió un error al eliminar el pago único', { variant: 'error' });
          }
        });
    }
  };

  const changeStatus = (res: SinglePayment | null, resStatus: string | null) => {
    if (res && resStatus) {
      sellerApi.singlePayments
        .change_status(res?.id, resStatus)
        .then(() => {
          enqueueSnackbar('Suscripción actualizada', { variant: 'success' });
          reloadRef?.current?.reloadResource?.();
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error al actualizar el pago único', { variant: 'error' });
        });
    }
  };

  const openActivateDialog = (res: SinglePayment) => {
    setActivateDialogOpen(true);
    setSinglePaymentRes(res);
  };

  const closeActivateDialog = () => {
    changeStatus(singlePaymentRes, singlePaymentRes?.status == 'active' ? 'inactive' : 'active');
    setActivateDialogOpen(false);
  };

  const openDeleteDialog = (res: SinglePayment) => {
    setDeleteDialogOpen(true);
    setSinglePaymentRes(res);
  };

  const closeDeleteDialog = () => {
    softDelete(singlePaymentRes);
    setDeleteDialogOpen(false);
  };

  function openReportsPopup() {
    setReportsDialogOpen(true);
  }

  function openMassiveSubscriptionBuyersPopup() {
    setMassiveSubscriptionBuyersPopup(true);
  }

  function openMassiveSubscriptionsPopup() {
    setMassiveSubscriptionsPopup(true);
  }

  const createLink = () => {
    history.push('single_payments/new');
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="RECAUDADO EN EL MES"
      data={formatCurrency(sellerData?.month_single_recaudation || 0)}
      kind="info1"
      style={styles.firstInfoContainer}
      icon={<BriefcaseIcon />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="Crear Pago Único"
      function={createLink}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/young%20man%20in%20headset%20using%20computer.svg"
      style={styles.linkContainer}
      width={width}
      access={access}
      route={resourceRoute}
      action={sellerActions.create}
    />,
    <InfoBox
      key={3}
      title="Acciones Masivas"
      function={openReportsPopup}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.linkContainer}
      width={width}
    />,
  ];
  const DownloadGCPSubscriptionTemplate = () => {
    window.open(
      'https://storage.googleapis.com/onlypays-public/assets/files/PlantillaPagosUnicos.xlsx',
      '_blank',
      'noreferrer'
    );
  };
  const handleFileChange = (event: any) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };
  const renderImportService = (
    <Fragment>
      <Typography mt={1} mb={1} paddingX={isMobile ? 0 : 3}>
        Para crear pagos únicos de forma masiva debes descargar el template con el formato y
        ejemplos que debes seguir para crearlos correctamente. En el encabezado de cada columna se
        agrega una descripción para aclarar qué debes ingresar en cada celda del excel. Una vez
        completado el archivo, debes cargarlo en el sistema y cuando se termine de procesar te
        llegará un mail con el resultado.
      </Typography>
      <div className={styles.excelImportContainer}>
        <Button
          variant="outlined"
          className={styles.excelImportButton}
          onClick={DownloadGCPSubscriptionTemplate}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faDownload} /> Descargar template
          </Typography>
        </Button>
        <Button variant="outlined" component="label" className={styles.excelImportButton}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faUpload} /> Cargar archivo
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
        <Typography variant="h6" sx={{ marginRight: '20px' }}>
          {file?.name}
        </Typography>
      </div>
    </Fragment>
  );

  const sendImportBuyerTemplate = () => {
    sellerApi.singlePayments
      .generateImportSubscriptionBuyersExcel(company?.id || '')
      .then(() => {
        enqueueSnackbar(
          'Te enviaremos un correo cuando hayamos terminado de generar la plantilla',
          { variant: 'info' }
        );
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
      })
      .finally(() => {
        setMassiveSubscriptionBuyersPopup(false);
      });
  };

  const renderImportBuyer = (
    <Fragment>
      <Typography mt={1} mb={1} paddingX={isMobile ? 0 : 3}>
        Para importar a tus clientes de forma masiva necesitarás primero descargar la plantilla, la
        cual en la primera hoja tendrá todos tus servicios junto con sus identificadores (primera
        columna) y en la segunda estará la plantilla a rellenar. En la plantilla deberás rellenar
        los datos de tus clientes y el servicio que desean adquirir copiando en la primera columna
        el identificador del servicio al que deseas añadirlo. Una vez completado el archivo, debes
        cargarlo en el sistema y cuando se termine de procesar te llegará un mail con el resultado.
      </Typography>
      <div className={styles.excelImportContainer}>
        <Button
          variant="outlined"
          className={styles.excelImportButton}
          onClick={sendImportBuyerTemplate}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faDownload} /> Descargar plantilla
          </Typography>
        </Button>
        <Button variant="outlined" component="label" className={styles.excelImportButton}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faUpload} /> Cargar archivo
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
        <Typography variant="h6" sx={{ marginRight: '20px' }}>
          {file?.name}
        </Typography>
      </div>
    </Fragment>
  );

  const importSubscriptionsExcel = () => {
    if (file) {
      sellerApi.singlePayments
        .importSubscriptionsExcel(file, company?.id || '')
        .then(() => {
          setFile(undefined);
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
          setMassiveSubscriptionsPopup(false);
          setReportsDialogOpen(false);
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
        })
        .finally(() => {
          setMassiveSubscriptionsPopup(false);
        });
    }
  };
  const importMassiveSubscriptionBuyersExcel = () => {
    if (file) {
      sellerApi.singlePayments
        .importMassiveSubscriptionBuyersExcel(file, company?.id || '')
        .then(() => {
          setFile(undefined);
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
          setMassiveSubscriptionBuyersPopup(false);
          setReportsDialogOpen(false);
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
        })
        .finally(() => {
          setMassiveSubscriptionsPopup(false);
        });
    }
  };
  return (
    <>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <CopyIcon />
          <span className={styles.startTitle}>Pagos Únicos</span>
        </Typography>
        <Typography variant="body1">
          Los <b>pagos únicos</b> se cobran solo una vez.
        </Typography>
      </div>

      {isMobile ? (
        <Button
          className={styles.toButton}
          onClick={() => history.push('single_payments/new')}
          variant="contained"
        >
          Crear Pago Único
        </Button>
      ) : null}

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      <ResourceList
        title="Pagos Únicos"
        queryFields="Nombre"
        setResource={setSinglePayment}
        getResourceList={sellerApi.singlePayments.list}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.singlePayments.generateExcel('company', parentId, query, filterParams)
        }
        resourceParent={company}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'value', label: 'Valor' },
          { key: 'status', label: 'Estado' },
          { key: 'recieved_payments', label: 'Pagos Recibidos' },
          { key: 'pending_payments', label: 'Pagos Pendientes' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'status' }, { key: 'value' }]}
        listColumns={{
          name: (res: SinglePayment) => res.name,
          value: (res: SinglePayment) => formatCurrency(res.render_amount, res.currency),
          status: (res: SinglePayment) => <StatusLabel status={res.status} type="singlePayment" />,
          recieved_payments: (res: SinglePayment) => res.recieved_single_payments,
          pending_payments: (res: SinglePayment) => res.pending_single_payments,
        }}
        listActionsHeaders={(res?: SinglePayment) => {
          if (res)
            return [
              { key: 'show', label: 'Ver Pago Único', icon: faEye },
              allowedRole(access, resourceRoute, sellerActions.update)
                ? res.status == 'active'
                  ? { key: 'change_status', label: 'Terminar', icon: faBellSlash }
                  : { key: 'change_status', label: 'Activar', icon: faBell }
                : null,
              allowedRole(access, resourceRoute, sellerActions.destroy)
                ? { key: 'delete', label: 'Eliminar', icon: faTrashCan, color: 'error' }
                : null,
            ];
          return [null];
        }}
        listActions={{
          show: (res: SinglePayment) => {
            dispatch(setSinglePayment(res));
            history.push(`/seller/single_payments/${res.id}`);
          },
          delete: (res: SinglePayment) => openDeleteDialog(res),
          change_status: (res: SinglePayment) =>
            res.status == 'active' ? openActivateDialog(res) : changeStatus(res, 'active'),
        }}
        innerRef={reloadRef}
        filtersKey="single_payments_index"
        persistFilters
      />
      <PopUp
        state={{ open: deleteDialogOpen, setOpen: setDeleteDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará el pago único. Todas las inscripciones a este pago único se eliminarán.
            Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeDeleteDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: activateDialogOpen, setOpen: setActivateDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se cancelarán todas las suscripciones de este servicio y se eliminarán todas las cuotas
            que no se han pagado. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeActivateDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setActivateDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: reportsDialogOpen, setOpen: setReportsDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Acciones Masivas
          </Typography>
        }
        content={
          <div>
            <div className={styles.reportRow} onClick={openMassiveSubscriptionsPopup}>
              <Typography variant="body1">Creación masiva de pagos únicos</Typography>
            </div>
            <div className={styles.reportRow} onClick={openMassiveSubscriptionBuyersPopup}>
              <Typography variant="body1">Creación masiva de inscripciones</Typography>
            </div>
          </div>
        }
      />
      <PopUp
        state={{ open: massiveSubscriptionsPopup, setOpen: setMassiveSubscriptionsPopup }}
        title={
          <Typography variant="h6" align="center">
            Creación masiva de Suscripciones
          </Typography>
        }
        content={renderImportService}
        extraActions={[
          <Button key={1} onClick={importSubscriptionsExcel} variant="contained">
            Enviar
          </Button>,
          <Button key={2} onClick={() => setMassiveSubscriptionsPopup(false)} variant="contained">
            Cancelar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: massiveSubscriptionBuyersPopup, setOpen: setMassiveSubscriptionBuyersPopup }}
        title={
          <Typography variant="h6" align="center">
            Creación masiva de inscripciones
          </Typography>
        }
        content={renderImportBuyer}
        extraActions={[
          <Button key={1} onClick={importMassiveSubscriptionBuyersExcel} variant="contained">
            Enviar
          </Button>,
          <Button
            key={2}
            onClick={() => {
              setMassiveSubscriptionBuyersPopup(false);
              setFile(undefined);
            }}
            variant="contained"
          >
            Cancelar
          </Button>,
        ]}
      />
    </>
  );
};

export default SinglePaymentsIndex;
