import React, { Fragment, useEffect, useState, useRef } from 'react';
import { SellerState, setPaymentPlan } from '../sellerSlice';
import { useHistory } from 'react-router-dom';
import { CollectionCompany, PaymentPlan } from '../../../app/type';
import { useSnackbar } from 'notistack';

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { useSelector } from '../../../app/hooks';
import ResourceList from '../../../common/components/ResourceList';
import InfoBox from '../../../common/components/InfoBox';
import { allowedRole, sellerActions } from '../../../common/utils/sellerAccess';

import { sellerApi } from '../../../common/api';
import { setDocumentTitle } from '../../../common/utils';
import variables from '../../../common/styles/variables.module.scss';

import styles from './Index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListUl,
  faDownload,
  faUpload,
  faEye,
  faMinusSquare,
  faTrashCan,
  faXmark,
  faClipboardCheck,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';
import { Drawer } from '@mui/material';
import TableList from '../../../common/components/TableList';

const Index = (): React.ReactElement => {
  setDocumentTitle('Planes de Pago');
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const [importPaymentPlansOpen, openImportPaymentPlans] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelPopup, setOpenCancelPopup] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [paymentPlanState, setPaymentPlanState] = useState<PaymentPlan>();
  const [collectionCompaniesDrawer, setCollectionCompaniesDrawer] = useState<boolean>(false);
  const [collectionCompanies, setCollectionCompanies] = useState<CollectionCompany[]>();
  const [openFinishPopup, setOpenFinishPopup] = useState<boolean>(false);
  const [openMassiveActionsPopup, setOpenMassiveActionsPopup] = useState<boolean>(false);
  const [importStatementsOpen, openImportStatements] = useState<boolean>(false);
  const reloadRef = useRef<{ reloadResource: () => void }>();
  const access: string = company?.seller?.access || '';
  const resourceRoute = 'payment_plan';

  const createPaymentPlan = () => {
    history.push('payment_plans/new');
  };

  const selectItem = (id: string) => {
    history.push(`payment_plans/${id}`);
  };

  const downloadTemplate = () => {
    window.open(
      company?.invoicing_provider
        ? 'https://storage.googleapis.com/onlypays-public/assets/files/Template%20Planes%20de%20pago%20con%20facturacio%CC%81n.xlsx'
        : 'https://storage.googleapis.com/onlypays-public/assets/files/Template%20Planes%20de%20pago.xlsx',
      '_blank',
      'noreferrer'
    );
  };

  const handleFileChange = (event: any) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const generatePaymentPlansList = () => {
    sellerApi.paymentPlans
      .excelForMassiveStatements(company?.id)
      .then(() => {
        enqueueSnackbar('Te enviaremos un correo con la plantilla generada', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
      })
      .finally(() => {
        setOpenMassiveActionsPopup(false);
        openImportStatements(false);
      });
  };

  const boxes = (width?: number): React.ReactElement[] => [
    <InfoBox
      key={1}
      title="Crear Plan de Pago"
      function={createPaymentPlan}
      kind="link"
      image="https://storage.cloud.google.com/onlypays-public/assets/images/young%20man%20working%20at%20desk.png"
      style={styles.firstLinkContainer}
      width={width}
      access={access}
      route={resourceRoute}
      action={sellerActions.create}
    />,
    <InfoBox
      key={2}
      title="Acciones Masivas"
      function={() => setOpenMassiveActionsPopup(true)}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.secondLinkContainer}
      width={width}
    />,
  ];

  const renderImportPaymentPlansContent = (
    <Fragment>
      <Typography variant="h6" align="center">
        Creación masiva de planes de pago
      </Typography>
      <Typography mt={1} mb={1} paddingX={isMobile ? 0 : 3}>
        Para crear Planes de Pago de forma masiva debes descargar el template con el formato y
        ejemplos que debes seguir para crearlos correctamente. En el encabezado de cada columna se
        agrega una descripción para aclarar qué debes ingresar en cada celda del excel. En la hoja 1
        debes agregar los datos descriptivos del Plan de pago. En la hoja 2 puedes agregar las
        cuotas asociadas a cada Plan. Finalmente debes cargar el archivo.
      </Typography>
      <div className={styles.excelImportContainer}>
        <Button variant="outlined" className={styles.excelImportButton} onClick={downloadTemplate}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faDownload} /> Descargar template
          </Typography>
        </Button>
        <Button variant="outlined" component="label" className={styles.excelImportButton}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faUpload} /> Cargar archivo
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
        <Typography variant="h6" sx={{ marginRight: '20px' }}>
          {file?.name}
        </Typography>
      </div>
    </Fragment>
  );

  const renderImportStatementsContent = (
    <Fragment>
      <Typography variant="h6" align="center">
        Creación masiva de cuotas
      </Typography>
      <Typography mt={1} mb={1} paddingX={isMobile ? 0 : 3}>
        Para crear cuotas de forma masiva debes generar la plantilla que contiene los planes de pago
        existentes. En la hoja &quot;Cuotas&quot; de este archivo debes crear cada una de las cuotas
        con el formato indicado en la primera fila (la cual debes eliminar), en donde el primer dato
        de cada fila debe ser el ID del plan de pago al que se quiere agregar la cuota, ID entregado
        en la hoja &quot;Planes de pago&quot;. Finalmente debes cargar el archivo.
      </Typography>
      <div className={styles.excelImportContainer}>
        <Button
          variant="outlined"
          className={styles.excelImportButton}
          onClick={generatePaymentPlansList}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faPaperPlane} /> Generar plantilla
          </Typography>
        </Button>
        <Button variant="outlined" component="label" className={styles.excelImportButton}>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <FontAwesomeIcon icon={faUpload} /> Cargar archivo
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
        <Typography variant="h6" sx={{ marginRight: '20px' }}>
          {file?.name}
        </Typography>
      </div>
    </Fragment>
  );

  const uploadPaymentPlansFile = () => {
    if (file) {
      sellerApi.paymentPlans
        .importPaymentPlans(file)
        .then(() => {
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
        })
        .finally(() => {
          openImportPaymentPlans(false);
          setOpenMassiveActionsPopup(false);
          setFile(undefined);
        });
    }
  };

  const uploadFileStatementsFile = () => {
    if (file) {
      sellerApi.paymentPlans
        .importStatements(file)
        .then(() => {
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
        })
        .finally(() => {
          openImportStatements(false);
          setOpenMassiveActionsPopup(false);
          setFile(undefined);
        });
    }
  };

  const markPaymentPlanAsCanceledAction = (paymentPlan: PaymentPlan) => {
    setOpenCancelPopup(true);
    setPaymentPlanState(paymentPlan);
  };

  const markPaymentPlanAsCanceled = () => {
    if (!paymentPlanState?.id) return;
    setOpenCancelPopup(false);
    sellerApi.paymentPlans
      .markAsCanceled(paymentPlanState.id)
      .then(() => {
        enqueueSnackbar('Se canceló el plan de pago', { variant: 'success' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch(() => enqueueSnackbar('No se pudo cancelar el plan de pago', { variant: 'error' }))
      .finally(() => {
        setPaymentPlanState(undefined);
      });
  };

  const deletePaymentPlanAction = (paymentPlan: PaymentPlan) => {
    setOpenDeletePopup(true);
    setPaymentPlanState(paymentPlan);
  };

  const deletePaymentPlan = () => {
    if (!paymentPlanState?.id) return;
    sellerApi.paymentPlans
      .destroy(paymentPlanState.id)
      .then(() => {
        enqueueSnackbar('Plan de pago eliminado exitosamente', { variant: 'success' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error: any) => {
        let msg = 'No se pudo eliminar el plan de pago';
        if (error.response.data?.message == 'has_payments')
          msg = 'No se puede eliminar porque tiene cuotas pagadas, puedes cancelar el plan de pago';
        enqueueSnackbar(msg, { variant: 'error' });
      })
      .finally(() => {
        setPaymentPlanState(undefined);
        setOpenDeletePopup(false);
      });
  };

  const markPaymentPlanAsFinishedAction = (paymentPlan: PaymentPlan) => {
    setOpenFinishPopup(true);
    setPaymentPlanState(paymentPlan);
  };

  const markPaymentPlanAsFinished = () => {
    if (!paymentPlanState?.id) return;
    setOpenFinishPopup(false);
    sellerApi.paymentPlans
      .markAsFinished(paymentPlanState.id)
      .then(() => {
        enqueueSnackbar('Plan de pago finalizado exitosamente', { variant: 'success' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch(() =>
        enqueueSnackbar('No se pudo finalizar el plan de pago, intenta nuevamente', {
          variant: 'error',
        })
      )
      .finally(() => {
        setPaymentPlanState(undefined);
      });
  };

  useEffect(() => {
    if (importPaymentPlansOpen === false) {
      setFile(undefined);
    }
  }, [importPaymentPlansOpen]);

  useEffect(() => {
    if (company?.id)
      sellerApi.companies.collectionCompanies(company?.id).then((data) => {
        setCollectionCompanies(data.data);
      });
  }, [company?.id]);

  return (
    <>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <FontAwesomeIcon icon={faListUl} />
          <span className={styles.startTitle}>Planes de pago</span>
        </Typography>
      </div>
      {isMobile ? (
        <Button className={styles.toButton} onClick={createPaymentPlan} variant="contained">
          Crear Plan de Pago
        </Button>
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {!company?.hide_payment_plan_edition &&
            boxes(4).map((box) => <Fragment key={box.key}>{box}</Fragment>)}
          {collectionCompanies && collectionCompanies.length > 0 && (
            <Fragment>
              <InfoBox
                key={2}
                title="Ver proyectos"
                function={() => setCollectionCompaniesDrawer(true)}
                kind="link"
                image="https://storage.googleapis.com/onlypays-public/assets/images/group%20of%20persons.svg"
                style={styles.secondLinkContainer}
                width={4}
              />
            </Fragment>
          )}
        </Grid>
      )}
      <div>
        <ResourceList
          title="Planes de pago"
          queryFields=""
          resourceParent={company}
          setResource={setPaymentPlan}
          excelDownloadMethod={(query, parentId, filterParams) =>
            sellerApi.paymentPlans.generateExcel('company', parentId, query, filterParams)
          }
          getResourceList={sellerApi.paymentPlans.list}
          innerRef={reloadRef}
          listHeaders={[
            { key: 'name', label: 'Nombre' },
            { key: 'buyerName', label: 'Nombre cliente' },
            { key: 'status', label: 'Estado' },
            { key: 'installments', label: 'Cuotas pagadas/Total' },
            { key: 'collection_company', label: 'Proyecto' },
          ]}
          listColumns={{
            name: (res) => res.product.name,
            buyerName: (res) => res.buyer.name,
            status: (res) => <StatusLabel status={res.status} type="paymentPlan" />,
            installments: (res) => `${res.total_paid_installments}/${res.total_installments}`,
            collection_company: (res) => res.collection_company_name,
          }}
          listActionsHeaders={(res) => {
            if (res) {
              return [
                allowedRole(access, resourceRoute, sellerActions.read)
                  ? { key: 'show', label: 'Ver detalle', icon: faEye }
                  : null,

                res.status === 'finished'
                  ? null
                  : company?.hide_payment_plan_edition
                  ? null
                  : allowedRole(access, resourceRoute, sellerActions.destroy)
                  ? {
                      key: 'finish',
                      label: 'Finalizar Plan de pago',
                      icon: faClipboardCheck,
                    }
                  : null,
                res.status === 'canceled'
                  ? null
                  : company?.hide_payment_plan_edition
                  ? null
                  : allowedRole(access, resourceRoute, sellerActions.destroy)
                  ? {
                      key: 'cancel',
                      label: 'Cancelar Plan de pago',
                      icon: faMinusSquare,
                      color: 'error',
                    }
                  : null,
                company?.hide_payment_plan_edition
                  ? null
                  : allowedRole(access, resourceRoute, sellerActions.destroy)
                  ? {
                      key: 'destroy',
                      label: 'Eliminar Plan de pago',
                      icon: faTrashCan,
                      color: 'error',
                    }
                  : null,
              ];
            }
            return [null];
          }}
          listActions={{
            show: (res: PaymentPlan) => selectItem(res.id),
            cancel: (res: PaymentPlan) => markPaymentPlanAsCanceledAction(res),
            destroy: (res: PaymentPlan) => deletePaymentPlanAction(res),
            finish: (res: PaymentPlan) => markPaymentPlanAsFinishedAction(res),
          }}
          listMobileHeaders={[
            { key: 'name' },
            { key: 'buyerName' },
            { key: 'status' },
            { key: 'installments' },
          ]}
        />
      </div>
      <PopUp
        state={{ open: importPaymentPlansOpen, setOpen: openImportPaymentPlans }}
        content={renderImportPaymentPlansContent}
        extraActions={[
          <Button key={1} onClick={uploadPaymentPlansFile} variant="contained">
            Guardar
          </Button>,
          <Button key={2} onClick={() => openImportPaymentPlans(false)} variant="outlined">
            Cancelar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: importStatementsOpen, setOpen: openImportStatements }}
        content={renderImportStatementsContent}
        extraActions={[
          <Button key={1} onClick={uploadFileStatementsFile} variant="contained">
            Guardar
          </Button>,
          <Button key={2} onClick={() => openImportStatements(false)} variant="outlined">
            Cancelar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openCancelPopup, setOpen: setOpenCancelPopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Al cancelar el plan de pago se eliminarán todas las cuotas que no se han pagado y no se
            cobrará más. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenCancelPopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={markPaymentPlanAsCanceled} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openDeletePopup, setOpen: setOpenDeletePopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Al eliminar el plan de pago se eliminarán todas las cuotas que no se han pagado y se
            borrará el registro de este. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenDeletePopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={deletePaymentPlan} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />
      <Drawer
        anchor="right"
        open={collectionCompaniesDrawer}
        onClose={() => setCollectionCompaniesDrawer(false)}
      >
        <div className={styles.paymentsDrawer}>
          <div className={styles.closePopup}>
            <div className={styles.xIcon} onClick={() => setCollectionCompaniesDrawer(false)}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: '#5f5f5f', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <Typography variant="h6">
            <b>Proyectos creados</b>
          </Typography>
          <TableList
            resources={collectionCompanies || []}
            headers={[
              { key: 'name', label: 'Nombre' },
              { key: 'external_id', label: 'Referencia' },
              { key: 'email', label: 'Correo contacto' },
              { key: 'phone', label: 'Teléfono contacto' },
            ]}
            columns={{
              name: (res: CollectionCompany) => res.name,
              external_id: (res: CollectionCompany) => res.external_id,
              email: (res: CollectionCompany) => res.email || '',
              phone: (res: CollectionCompany) => res.phone || '',
            }}
          />
        </div>
      </Drawer>
      <PopUp
        state={{ open: openFinishPopup, setOpen: setOpenFinishPopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se actualizará el estado del plan de pago a &quot;Finalizado&quot; y se eliminarán todas
            las cuotas que no se han pagado. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenFinishPopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={markPaymentPlanAsFinished} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openMassiveActionsPopup, setOpen: setOpenMassiveActionsPopup }}
        title={
          <Typography variant="h6" align="center">
            Acciones Masivas
          </Typography>
        }
        content={
          <div>
            {allowedRole(access, resourceRoute, sellerActions.create) && (
              <div>
                <div className={styles.reportRow} onClick={() => openImportPaymentPlans(true)}>
                  <Typography variant="body1">Crear planes de pago</Typography>
                </div>
                <div className={styles.reportRow} onClick={() => openImportStatements(true)}>
                  <Typography variant="body1">Crear cuotas para planes de pagos</Typography>
                </div>
              </div>
            )}
            {allowedRole(access, resourceRoute, sellerActions.list) && (
              <div
                className={styles.reportRow}
                onClick={() => history.replace(`/seller/file_imports?load_type=statements`)}
              >
                <Typography variant="body1">Ver cargas masivas de cuotas</Typography>
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default Index;
