import React from 'react';

import { sellerApi } from '../../../common/api';
import { FileImport } from '../../../app/type';

import ResourceList from '../../../common/components/ResourceList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { SellerState } from '../sellerSlice';
import { useSnackbar } from 'notistack';
import { useQuery } from '../../../common/utils';

const FileImportIndex = (): React.ReactElement => {
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const load_type = useQuery().load_type;

  const downloadResult = (res: FileImport) => {
    sellerApi.fileImport
      .generateStatementsExcel(res.id)
      .then((res) => {
        console.log(res);
        enqueueSnackbar('Te enviamos un correo con el resultado de la carga', {
          variant: 'success',
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Ocurrió un error, intentalo nuevamente', {
          variant: 'error',
        });
      });
  };

  return (
    <div>
      <ResourceList
        title="Cargas masivas de cuotas"
        queryFields=""
        resourceParent={{ id: company?.id || '' }}
        getResourceList={() =>
          sellerApi.fileImport.list(company?.id || '', undefined, undefined, {
            load_type: load_type,
          })
        }
        listHeaders={[
          { key: 'created_at', label: 'Fecha de importación' },
          { key: 'user_name', label: 'Usuario' },
          { key: 'url', label: 'Archivo cargado' },
          { key: 'total', label: 'Datos cargados' },
        ]}
        listColumns={{
          created_at: (object: FileImport) => object.created_at,
          user_name: (object: FileImport) => object.user?.name || <i>Usuario eliminado</i>,
          url: (object: FileImport) => (
            <a href={object.url} target="_blank" rel="noreferrer noopener">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              {'  '}Ver archivo
            </a>
          ),
          total: (object: FileImport) => object.total,
        }}
        hideQuery
        listMobileHeaders={[{ key: 'created_at' }, { key: 'user_name' }]}
        listActionsHeaders={(res) => {
          if (res) {
            return [{ key: 'download', label: 'Descargar resultado', icon: faFileExcel }];
          }
          return [null];
        }}
        listActions={{
          download: (res: FileImport) => {
            downloadResult(res);
          },
        }}
      />
    </div>
  );
};

export default FileImportIndex;
